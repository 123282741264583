import React from 'react';
import styled from '@emotion/styled/macro';

const Counter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin-left: auto;
  border-radius: 100px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  color: #08304b;
  font-size: 12px;
  line-height: 125%;
  background: #fff;
`;

export default ({ value }) => (
  <Counter>
    <span>{value}</span>
  </Counter>
);
