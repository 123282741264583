import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getPoint } from '../api';
import { locator } from '../locator';
// import { intervalRAF, cancelIntervalRAF } from '../helpers';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Card } from '../card';
import { Icon } from '../icon';
import { RouteTooltip } from '../route';
import { ReactComponent as BgImage } from './img/bg.svg';
// import locate from './img/locate.svg';
import { pushEvent } from '../gtm';

const AP = window.AP;
const APE = window.APE;
const APL = window.APL;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Rim = styled.div`
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  pointer-events: none;
`;

const Bg = styled(BgImage)`
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  circle,
  path {
    transition: all 0.3s ease;
  }
`;

const InfoWindow = styled.div`
  position: absolute;
  z-index: 2000;
  bottom: calc(100% + 52px + 16px);
  left: 50%;
  transform: translate(-50%, 0);
  width: 280px;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 1998;
  width: 300vw;
  height: 300vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const activeState = css`
  ${Bg} {
    circle,
    path {
      fill: #fe5000;
    }
  }

  ${StyledIcon} {
    filter: brightness(0) invert(1);
  }
`;

const Pin = styled.div`
  position: relative;
  width: 40px;
  height: 52px;
  overflow: visible;
  transform-origin: 50% 100%;
  transform: translate(-50%, -100%);
  transition: all 0.3s ease-in-out;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  z-index: ${props => (props.active ? 1999 : 0)};

  &:hover {
    ${activeState}
  }

  ${props => (props.active ? activeState : '')}

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    width: 16px;
    height: 16px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) scaleX(2);
    background: radial-gradient(
      6px at 50% 50%,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.3;
  }
`;

export const Marker = ({
  thisPoint,
  thisPoint: { icon },
  type,
  lat,
  lng,
  handleRoute,
  handleRouteCancel,
  isInRoute,
  showPromo,
  ...props
}) => {
  const NS = type === 'EMBED' ? APE : AP;

  const [isActive, setActive] = useState(false);
  const [isRoute, setRoute] = useState(isInRoute);
  const [point, setPoint] = useState(null);

  const handleClick = e => {
    e.stopPropagation();

    if (isActive) {
      setActive(!isActive);
    } else {
      const { id } = props;

      pushEvent('Клик на иконку акции');

      setActive(!isActive);
      getPoint(id, { include: 'city' }).then(point => {
        setPoint(point);
      });

      if (isMobile) {
        NS.map.panToWithOffset(
          new NS.maps.LatLng(lat, lng),
          0,
          showPromo ? -180 : -100
        );
      } else {
        NS.map.panToWithOffset(new NS.maps.LatLng(lat, lng), 0, -150);
      }
    }
  };

  const makeRoute = travelMode => {
    thisPoint.travelMode = travelMode;
    handleRoute(thisPoint);
    setRoute(travelMode);

    navigator.geolocation.getCurrentPosition(position => {
      NS.directionsDisplay.setMap(NS.map);
      const directionsService = new NS.maps.DirectionsService();
      const destination = new NS.maps.LatLng(lat, lng);
      const origin = new NS.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );

      // if (NS.location && NS.location.setPosition)
      //   NS.location.setPosition(origin);

      // if (!NS.location) {
      //   NS.location = new NS.maps.Marker({
      //     position: origin,
      //     map: NS.map,
      //     icon: {
      //       url: locate,
      //       scaledSize: new NS.maps.Size(24, 24),
      //       origin: new NS.maps.Point(0, 0),
      //       anchor: new NS.maps.Point(12, 12),
      //     },
      //   });
      // }

      const request = {
        destination,
        origin,
        travelMode,
      };

      directionsService.route(request, (response, status) => {
        if (status === 'OK') {
          NS.directionsDisplay.setDirections(response);
          APL.NS = NS;
          locator();
        }
      });
    });
  };

  const cancelRoute = () => {
    NS.directionsDisplay.setMap(null);

    // APL.NS = null;

    // if (NS.location) NS.location.setMap(null);
    // if (NS.intervalInstance) cancelIntervalRAF(NS.intervalInstance);

    handleRouteCancel();
    setRoute(false);
  };

  return (
    <>
      <Pin active={isActive} onClick={handleClick}>
        <Bg />

        <Rim>
          <StyledIcon type={icon} />
        </Rim>
      </Pin>

      {isRoute ? (
        <RouteTooltip travelMode={isRoute} closeHandler={() => cancelRoute()} />
      ) : (
        isActive && (
          <InfoWindow>
            {point && (
              <Card
                entity={point}
                gtm={() => pushEvent('Клик на акцию')}
                closeHandler={() => setActive(!isActive)}
                routeHandler={travelMode => makeRoute(travelMode)}
                arrow
              />
            )}
          </InfoWindow>
        )
      )}

      {isActive && (
        <Overlay
          onClick={e => {
            e.stopPropagation();
            !isRoute && setActive(!isActive);
          }}
        />
      )}
    </>
  );
};
