import React, { useContext } from 'react';
import { Aperol } from '../context';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Btn, BtnCSS } from '../btn';
import { ReactComponent as NothingImage } from './img/nothing.svg';

const filters = [
  {
    title: 'События',
    id: '',
  },
  {
    title: 'Дегустации',
    id: 6,
  },
  {
    title: 'Мероприятия',
    id: 0,
  },
  {
    title: 'Акции баров',
    id: 2,
  },
  {
    title: 'Акции магазинов',
    id: 5,
  },
  {
    title: 'Флагманские заведения',
    id: 1,
  },
  {
    title: 'Террасы',
    id: 7,
  },
];

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);

  @media (max-width: 767px) {
    padding: 0 20px 60px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Nothing = styled.figure`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: visible;
  margin: 0 0 24px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 248px;
    height: 248px;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 767px) {
    width: 100px;
    height: 100px;

    svg {
      width: 124px;
      height: 124px;
    }
  }
`;

const Heading = styled.header`
  font-size: 24px;
  line-height: 100%;
  font-weight: 700;
  color: #08304b;
  text-transform: uppercase;
  margin: 0 0 24px;
  text-align: center;
`;

const StyledBtn = styled(Btn)`
  margin: 0;
  background: #fc5001;
  color: #fff;
  max-width: 240px;

  &:hover {
    color: #000;
    background: #fff;
  }
`;

const StyledLink = styled(Link)`
  ${BtnCSS}
  margin: 0;
  background: #fc5001;
  color: #fff;

  &:hover {
    color: #000;
    background: #fff;
  }
`;

export const Empty = ({ isSearch, ...props }) => {
  const { methods, state } = useContext(Aperol);
  const { filter } = state;
  const { switchFilter, setDate } = methods;

  const what = filters.find(item => item.id === filter) || filters[0];

  return (
    <Wrapper {...props}>
      <Content>
        <Nothing>
          <NothingImage />
        </Nothing>

        <Heading>
          {isSearch
            ? 'Ничего не найдено'
            : `${what.title} поблизости не найдены`}
        </Heading>

        {isSearch ? (
          <StyledLink to="/">Вернуться назад</StyledLink>
        ) : (
          <StyledBtn
            onClick={() => {
              setDate(new Date());
              switchFilter('');
            }}
          >
            Показать все доступные
          </StyledBtn>
        )}
      </Content>
    </Wrapper>
  );
};
