import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { getPoints } from '../api';
import styled from '@emotion/styled/macro';
import { Btn } from '../btn';
import { ReactComponent as Pin } from './img/pin.svg';

const Wrapper = styled.section`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const Heading = styled.header`
  margin-bottom: 10px;
  color: #08304b;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const Items = styled.article``;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

const Serial = styled.span`
  display: block;
  margin: 0 20px 0 0;
  color: #08304b;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 125%;
  }
`;

const Address = styled(Link)`
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }

  header {
    margin-bottom: 4px;
    color: #08304b;
    font-size: 14px;
    line-height: 130%;
  }

  p {
    margin: 0;
    color: rgba(8, 48, 75, 0.6);
    font-size: 14px;
    line-height: 130%;
  }
`;

const Show = styled(Btn)`
  margin: 0 0 0 auto;

  svg {
    display: none;
  }

  @media (min-width: 1025px) {
    &:hover {
      svg {
        filter: none;

        path,
        circle {
          fill: #fff;
        }

        circle:last-child {
          fill: #fc5001;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    padding: 0;
    font-size: 0;
    line-height: 1;

    svg {
      display: block;
      width: 14px;
      height: 18px;
      margin-right: 0;
    }
  }
`;

export const PointAdrs = ({ point, handleClick, ...props }) => {
  const [points, setPoints] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);

  const from = format(new Date(), 'YYYY-MM-DD');
  const { city, title, place } = point;

  useEffect(() => {
    getPoints(city.data.id, {
      title,
      place,
      include: 'city',
      from,
      page,
    }).then(r => {
      setPoints([...points, ...r.data]);
      setMeta(r.meta);
    });
  }, [page]);

  const showPoints = points && points.length > 0;
  const showMore =
    meta && meta.pagination.current_page < meta.pagination.total_pages;

  return (
    <Wrapper {...props}>
      <Heading>Список адресов</Heading>

      {showPoints && (
        <Items>
          {points.map((item, index) => (
            <Item key={index}>
              <Serial>{index + 1}</Serial>

              <Address to={`/points/${item.id}`}>
                <header>{item.place}</header>

                <p>
                  {item.city.data.name}, {item.address}
                </p>
              </Address>

              <Show onClick={() => handleClick(item)}>
                Показать на карте <Pin />
              </Show>
            </Item>
          ))}
        </Items>
      )}

      {showMore && (
        <Btn
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Показать больше
        </Btn>
      )}
    </Wrapper>
  );
};
