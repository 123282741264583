import React, { useState, useEffect } from 'react';
import { getFeedbackTopics } from '../api';
import Select, { components } from 'react-select';
import styles from './styles';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowIcon />
  </components.DropdownIndicator>
);

export const FeedbackTopics = ({ handleChange, ...props }) => {
  const [topics, setTopics] = useState([]);
  const [topicsOpts, setTopicsOpts] = useState([]);

  useEffect(() => {
    getFeedbackTopics().then(topics => {
      setTopics(topics);

      const options = topics.map(topic => {
        return {
          label: topic.name,
          value: topic.id,
        };
      });

      setTopicsOpts(options);
    });
  }, []);

  return (
    topics.length > 0 && (
      <Select
        options={topicsOpts}
        onChange={({ value }) => handleChange(value)}
        noOptionsMessage={() => 'Нет совпадений'}
        components={{ DropdownIndicator }}
        styles={styles}
        placeholder="Выберите тему"
        {...props}
      />
    )
  );
};
