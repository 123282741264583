export const options = [
  {
    label: 'За всё время',
    value: 'all',
  },
  {
    label: 'За три дня',
    value: 'three',
  },
  {
    label: 'За неделю',
    value: 'week',
  },
  {
    label: 'За месяц',
    value: 'month',
  },
];
