import styled from '@emotion/styled/macro';
import { StyledLogo } from '../logo';
import { Bottle } from '../bottle';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;

  background: #fc5001;
  ${StyledLogo} {
    display: block;
    width: 300px;
    height: 300px;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: auto;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    padding: 60px 10px;
  }
`;

export const Heading = styled.header`
  margin: 0 0 32px;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;

  span {
    display: block;
  }

  @media (max-width: 767px) {
    font-size: 20px;

    span {
      display: inline;
    }
  }
`;

export const Answers = styled.div`
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 0 16px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1170px) {
    justify-content: center;

    button {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    button {
      margin: 0 0 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const StyledBottle = styled(Bottle)`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  img {
    position: relative;
    height: 116%;
    left: 4%;
    top: -4%;
    max-height: none;
  }
`;
