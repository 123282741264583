import React, { forwardRef } from 'react';
import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';

export const BtnIconCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;

  @media (min-width: 1025px) {
    &:hover {
      background: #fe5000;

      img {
        filter: brightness(0) invert(1);
      }

      svg {
        polygon,
        path,
        circle {
          fill: #fff;
        }
      }
    }
  }
`;

const Button = styled.button`
  ${BtnIconCSS};

  &:last-child:not(:nth-of-type(1)):not(:nth-of-type(3)) {
    margin-left: 14px;
  }
`;

export const BtnIcon = forwardRef((props, ref) => (
  <Button {...props} ref={ref} />
));
