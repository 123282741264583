import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  color: #08304b;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
  text-decoration: none;
  transition: all ease-in-out 0.3s;

  svg {
    margin-right: 11px;
  }

  &:hover {
    color: #06263c;
  }

  @media (max-width: 1024px) {
    margin-left: 60px;

    ~ header {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const Back = props => {
  const handleClick = e => {
    if (document.referrer.indexOf(window.location.host) !== -1) {
      e.preventDefault();
      e.stopPropagation();
      window.history.back(-1);
    }
  };

  return (
    <StyledLink {...props} onClick={handleClick}>
      <ArrowIcon />
      <span>Назад к списку</span>
    </StyledLink>
  );
};
