export default {
  control: predefined => ({
    ...predefined,
    zIndex: '10',
    minHeight: '40px',
    border: 'none',
    boxShadow:
      '0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '20px',
    paddingLeft: '20px',
    backgroundColor: '#fff',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'hsl(0,0%,80%)',
    },
  }),
  dropdownIndicator: (predefined, state) => ({
    ...predefined,
    paddingRight: '20px',

    svg: {
      transition: 'transform 0.3s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : null,
    },
  }),
  group: predefined => ({
    ...predefined,
    position: 'relative',
    marginBottom: '15px',
    paddingBottom: '15px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '20px',
      width: 'calc(100% - 40px)',
      height: '1px',
      backgroundColor: 'rgba(8, 48, 75, 0.1)',
    },
  }),
  groupHeading: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: predefined => ({
    ...predefined,
    top: '0',
    margin: '0',
    paddingTop: '50px',
    borderRadius: '20px',
  }),
  menuList: (predefined, state) => ({
    ...predefined,
    marginTop: '4px',
    marginBottom: '16px',
    padding: '0',
    // overflow: 'hidden',

    '&::after': {
      // content: state.isFade ? '""' : 'none',
      // content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '0',
      zIndex: '1',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
      width: '100%',
      height: '40px',
      background:
        'linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
      pointerEvents: 'none',
    },
  }),
  option: (predefined, state) => ({
    ...predefined,
    paddingTop: '5px',
    paddingRight: '20px',
    paddingBottom: '5px',
    paddingLeft: '20px',
    color: state.isSelected ? '#fc5001' : '#08304b',
    fontSize: '14px',
    lineHeight: '120%',
    // textTransform: 'uppercase',
    backgroundColor: 'transparent',

    '&:hover': {
      color: '#fc5001',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },

    '&:active': {
      color: '#fc5001',
    },
  }),
  placeholder: predefined => ({
    ...predefined,
    color: 'rgba(8, 48, 75, 0.4)',
    fontSize: '14px',
    lineHeight: '120%',
    // textTransform: 'uppercase',
  }),
  singleValue: predefined => ({
    ...predefined,
    color: '#08304b',
    fontSize: '14px',
    lineHeight: '120%',
    // textTransform: 'uppercase',
  }),
  valueContainer: predefined => ({
    ...predefined,
    padding: '0',
  }),
};
