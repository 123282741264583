import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { Aperol } from '../context';
import { pushEvent } from '../gtm';
import { ReactComponent as AppleBadge } from './img/apple.svg';
import { ReactComponent as GoogleBadge } from './img/google.svg';

const Items = styled.div`
  display: flex;
  justify-content: flex-start;

  a {
    &:first-of-type {
      margin-right: 6px;
    }
  }

  svg {
    height: 40px;
  }
`;

export const Stores = props => {
  const { state } = useContext(Aperol);
  const { settings } = state;

  const showStores = state.settings;
  const showApple = settings && settings.apple;
  const showGoogle = settings && settings.google;

  return (
    showStores && (
      <Items {...props}>
        {showApple && (
          <a
            href={settings.apple}
            onClick={() =>
              pushEvent('Клик на сторы / App Store')
            }
            target="_blank"
            rel="noopener noreferrer"
            title=""
          >
            <AppleBadge />
          </a>
        )}

        {showGoogle && (
          <a
            href={settings.google}
            onClick={() =>
              pushEvent('Клик на сторы / Play Store')
            }
            target="_blank"
            rel="noopener noreferrer"
            title=""
          >
            <GoogleBadge />
          </a>
        )}
      </Items>
    )
  );
};
