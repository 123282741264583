// import React, { useState, useLayoutEffect, useRef } from 'react';
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import styled from '@emotion/styled/macro';
// import 'objectFitPolyfill';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import './slick.css';

const Wrapper = styled.section`
  overflow: hidden;

  .slick-slider {
    margin-bottom: 20px;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .slick-slide {
    position: relative;
  }

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-position: 50% 0;
  object-fit: cover;

  @media (max-width: 768px) {
    /* position: static;
    width: auto;
    height: auto; */
  }
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Arrows = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Arrow = styled.button`
  position: relative;
  width: 20px;
  height: 16px;
  border: none;
  background-color: transparent;
  outline: none;
  transition: all ease-in-out 0.15s;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Prev = styled(Arrow)``;

const Next = styled(Arrow)`
  svg {
    transform: translate(-50%, -50%) scale(-1, -1);
  }
`;

const Pager = styled.aside`
  color: #08304b;
  font-size: 14px;
  line-height: 120%;
`;

export const PartySlider = ({ id, slides, cover }) => {
  const [curSlide, setCurSlide] = useState(1);
  const sliderRef = useRef(null);

  const cfg = {
    infinite: true,
    speed: 500,
    className: 'party-slider',
    // slidesToShow: 1.5,
    // slidesToScroll: 1,
    arrows: false,
    dots: false,
    variableWidth: true,
    adaptiveHeight: false,
    beforeChange: current => setCurSlide(current + 1),
    responsive: [
      {
        breakpoint: 767,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        settings: {
          dots: true,
          // adaptiveHeight: true,
          variableWidth: false,
        },
      },
    ],
  };

  // useLayoutEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   objectFitPolyfill();
  // }, []);

  const handleArrowClick = type => {
    // console.log(sliderRef.current);
    if (type === 'prev') {
      sliderRef.current.slickPrev();
      return;
    }

    sliderRef.current.slickNext();
  };

  return (
    <Wrapper>
      <Slider ref={sliderRef} {...cfg}>
        {slides ? (
          slides.map((slide, index) => (
            <Img src={slide.large} data-object-fit key={id + index} />
          ))
        ) : (
          <Img src={cover.large} />
        )}
      </Slider>

      {slides.length > 1 ? (
        <Nav>
          <Arrows>
            <Prev onClick={() => handleArrowClick('prev')}>
              <ArrowIcon />
            </Prev>

            <Next onClick={() => handleArrowClick('next')}>
              <ArrowIcon />
            </Next>
          </Arrows>

          <Pager>
            {curSlide} / {slides.length}
          </Pager>
        </Nav>
      ) : null}
    </Wrapper>
  );
};
