import styled from '@emotion/styled/macro';

export const About = styled.section`
  width: 100%;
  max-width: 830px;
  margin-bottom: 20px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 145%;

  article {
    margin-bottom: 24px;

    &:last-child,
    &:only-child {
      margin-bottom: 0;
    }
  }

  p,
  ul,
  ol {
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: #fc5001;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 140%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 24px;
    color: #08304b;
    font-size: 24px;
    font-weight: 700;
    line-height: 125%;
    text-transform: uppercase;

    @media (max-width: 767px) {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 125%;
    }
  }
`;
