import React, { useState, useContext, useEffect, useRef } from 'react';
import { Aperol } from '../context';
import { format } from 'date-fns';
import { postPointsSearch } from '../api';
import styled from '@emotion/styled/macro';
import { Heading } from '../typo';
import { Btn } from '../btn';
import { Card } from '../card';
import { Empty } from '../empty';

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const Wrapper = styled.section`
  position: relative;
  z-index: 10;
  padding: 20px 0 40px;
  background: #fff;
  min-height: 100vh;

  @media (max-width: 767px) {
    padding-top: 30px;
    height: calc(var(--vh, 1vh) * 100 - 100px + 60px);
  }
`;

const Content = styled.div`
  padding: 0 20px;

  @media (max-width: 767px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const Cards = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  > article {
    width: calc((100% / 3) - (40px / 3));
    margin-right: 20px;
    margin-bottom: 20px;
    text-decoration: none;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const StyledEmpty = styled(Empty)`
  /* position: relative; */
`;

export const SearchPage = props => {
  const { state, methods } = useContext(Aperol);
  const { toggleFrontView } = methods;
  const { city } = state;

  const prevCity = usePrevious(city) || city;

  const params = new URLSearchParams(props.location.search);
  const q = params.get('q') || '';
  const from = format(new Date(), 'YYYY-MM-DD');

  const prevQuery = usePrevious(q) || q;

  useEffect(() => {
    toggleFrontView(false);

    return () => {
      toggleFrontView(true);
    };
  }, []);

  const [points, setPoints] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!city.id) {
      return;
    }

    if (!q) {
      return;
    }

    const isCityChanged = city.id !== prevCity.id;
    const isQueryChanged = q !== prevQuery;

    postPointsSearch(city.id, {
      include: 'city',
      from,
      q,
      page: isCityChanged ? 0 : page,
    }).then(r => {
      setPoints(
        isCityChanged || isQueryChanged ? r.data : [...points, ...r.data]
      );
      setMeta(r.meta);
    });
  }, [q, city, page]);

  const showPoints = points && points.length > 0;
  const showMore =
    meta && meta.pagination.current_page < meta.pagination.total_pages;

  return (
    <Wrapper {...props}>
      <Content>
        <Heading>Результаты поиска «{q}»</Heading>

        {showPoints ? (
          <>
            <Cards>
              {points.map(item => (
                <Card entity={item} type="point" key={item.id} />
              ))}
            </Cards>

            {showMore && (
              <Btn
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Показать больше
              </Btn>
            )}
          </>
        ) : (
          <StyledEmpty isSearch={true} />
        )}
      </Content>
    </Wrapper>
  );
};
