import React, { useState, useEffect, useContext } from 'react';
import Select, { components } from 'react-select';
import styles from './styles';
import { getCities } from '../api';
import { Aperol } from '../context';
import { getValue } from '../helpers';
import { pushEvent } from '../gtm';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowIcon />
  </components.DropdownIndicator>
);

export const Cities = ({ setCity }) => {
  const { state } = useContext(Aperol);
  const { city } = state;

  const [cities, setCities] = useState([]);
  const [citiesOpts, setCitiesOpts] = useState([]);

  useEffect(() => {
    getCities().then(cities => {
      setCities(cities);

      const grouped = [
        {
          label: '',
          options: [],
        },
      ];
      let other = [];

      cities.forEach(city => {
        if (city.name === 'Москва' || city.name === 'Санкт-Петербург') {
          grouped[0].options = [
            ...grouped[0].options,
            {
              label: city.name,
              value: city.id,
            },
          ];

          return;
        }

        other = [
          ...other,
          {
            label: city.name,
            value: city.id,
          },
        ];
      });

      const options = [...grouped, ...other];
      setCitiesOpts(options);
    });
  }, []);

  return (
    city.id && (
      <Select
        defaultValue={{ value: city.id, label: city.name }}
        className="ap-cities-select"
        options={citiesOpts}
        onChange={({ value }) => {
          pushEvent(`Клик на город / ${getValue(value, cities, 'id').name}`);
          setCity(getValue(value, cities, 'id'));
        }}
        noOptionsMessage={() => 'Нет совпадений'}
        components={{ DropdownIndicator }}
        styles={styles}
        placeholder="поиск..."
      />
    )
  );
};
