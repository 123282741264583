import React from 'react';
import styled from '@emotion/styled/macro';
import logo from './img/logo.svg';

const Img = styled.img`
  @media (max-width: 767px) {
    width: 90px;
    height: 90px;
  }
`;

export { Img as StyledLogo };

export const Logo = props => <Img src={logo} alt="" {...props} />;
