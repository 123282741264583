import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as LogoImage } from './img/logo.svg';
import { ReactComponent as RotateImage } from './img/rotate.svg';

const Wrapper = styled.section`
  display: none;
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #fc5001;

  /* @media (max-width: 812px) and (orientation: landscape) { */
  @media (max-width: 812px) and (min-aspect-ratio: 13/9) {
    display: flex;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  svg {
    &:nth-of-type(2) {
      margin: 24px 0;
    }
  }
`;

const Heading = styled.header`
  margin: 0;
  font-size: 24px;
  line-height: 100%;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
`;

export const Rotate = props => {
  return (
    <Wrapper {...props}>
      <Content>
        <LogoImage />
        <RotateImage />
        <Heading>Пожалуйста, поверните телефон</Heading>
      </Content>
    </Wrapper>
  );
};
