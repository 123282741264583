import React from 'react';
import styled from '@emotion/styled/macro';
import { pushEvent } from '../gtm';

const Toggler = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 30px;
  right: 80px;
  z-index: 90;

  > span {
    color: rgba(8, 48, 75, 0.4);
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;

    &.list {
      display: inline;
    }

    &.active {
      color: rgba(8, 48, 75, 0.8);
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Toggle = styled.div`
  position: relative;
  width: 30px;
  height: 20px;
  margin: 0 10px;
  border-radius: 100px;
  background-color: #08304b;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: ${({ state }) => (state ? '12px' : '3px')};
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #fff;
    transition: left ease-in-out 0.3s;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Switch = ({ view, switchView }) => {
  const handleSwitch = () => {
    pushEvent('Клик на переключатель вида');
    if (view === 'map') {
      switchView('list');
      return;
    }

    switchView('map');
  };

  const handleLabelClick = type => {
    pushEvent('Клик на переключатель вида');
    switchView(type);
  };

  return (
    <Toggler>
      <span
        className={view === 'map' ? 'active' : null}
        onClick={() => handleLabelClick('map')}
      >
        Карта
      </span>
      <Toggle state={view === 'list'} onClick={handleSwitch} />
      <span
        className={view === 'list' ? 'active' : null}
        onClick={() => handleLabelClick('list')}
      >
        список
      </span>
    </Toggler>
  );
};
