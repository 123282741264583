import styled from '@emotion/styled/macro';

export const Heading = styled.header`
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  color: #fc5001;
  font-size: 64px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;

  span {
    display: block;
  }

  @media (max-width: 1660px) {
    font-size: 52px;
  }

  @media (max-width: 1430px) {
    font-size: 42px;
  }

  @media (max-width: 1200px) {
    font-size: 36px;
  }

  @media (max-width: 1024px) {
    padding-left: 60px;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 120%;

    span {
      display: inline;
    }
  }
`;
