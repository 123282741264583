import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';

export const BtnCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 40px;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
  background: #fff;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  text-decoration: none;
  color: #000;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  @media (min-width: 1025px) {
    &:hover {
      background: #fe5000;
      color: #fff;

      img,
      svg {
        filter: brightness(0) invert(1);
      }
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  svg,
  img {
    display: block;
    position: relative;
    z-index: 1;
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Btn = styled.button`
  ${BtnCSS}
  margin-top: 20px;
`;
