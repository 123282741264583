import React, { useContext } from 'react';
import { Aperol } from '../context';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import styled from '@emotion/styled/macro';
import { Menu } from '../menu';
import { Logo } from '../logo';
import { Cities } from '../cities';
import { Nav } from '../nav';
import { Legal } from '../legal';
import { Social } from '../social';
import { Stores } from '../stores';
import { Company } from '../company';
import { Promo } from '../promo';
import { Bottle } from '../bottle';
import { ReactComponent as CloseIcon } from './img/close.svg';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 400px;
  background: #fe5000;
  overflow: auto;

  @media (min-width: 1025px) {
    -ms-overflow-style: none;
    scrollbar-color: #000000 #fedbca;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      /* background-color: #fe5000; */
      background-color: #e44800;
      border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
      background-color: #fedbca;
    }
  }

  @media (max-width: 1600px) {
    width: 360px;
  }

  @media (max-width: 1024px) {
    z-index: 120;
    transition: all ease 0.8s;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 60px 0 0; */
  /* padding-top: 50px; */

  @media (max-width: 1024px) {
    padding-top: 30px;
  }
`;

const Close = styled.button`
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 10px;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  /* margin-bottom: 50px; */

  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

const Center = styled.section`
  margin-bottom: auto;
  padding: 0 40px;

  @media (max-width: 1600px) {
    padding: 0 20px;
  }

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const Bottom = styled.section`
  margin: 40px 0 20px;
  padding: 0 40px;

  @media (max-width: 1600px) {
    padding: 0 20px;
  }

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const Links = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;

  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;

const StyledLegal = styled(Legal)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;

    a {
      &:nth-of-type(2) {
        order: 999;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
`;

const StyledStores = styled(Stores)`
  margin-right: auto;

  @media (max-width: 767px) {
    margin-left: auto;
    margin-bottom: 20px;
  }
`;

const StyledPromo = styled(Promo)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledBottle = styled(Bottle)`
  position: absolute;
  z-index: -1;
  top: 240px;
  bottom: ${({ showPromo }) => (showPromo ? '80px' : '0px')};
  left: 0;
  right: 0;
  z-index: -1;

  img {
    position: relative;
    left: 8%;
    top: 8%;
  }

  @media (max-width: 1024px) {
    bottom: 0;
    top: 200px;
  }

  @media (max-width: 767px) {
    top: 120px;
  }
`;

export const Sidebar = ({ isOpen, toggleSidebar, setCity, count }) => {
  const { state } = useContext(Aperol);
  const { showPromo } = state;

  return (
    <Wrapper isOpen={isOpen}>
      <Container>
        <Close onClick={toggleSidebar}>
          <CloseIcon />
        </Close>

        <Menu alternative toggleSidebar={toggleSidebar} />

        <StyledPromo />

        <LogoLink to="/" onClick={toggleSidebar}>
          <Logo />
        </LogoLink>

        <Center>
          <Cities setCity={setCity} />
          <Nav count={count} onClick={toggleSidebar} />
        </Center>

        <Bottom>
          <Media query="(max-width: 767px)">
            {matches =>
              matches ? (
                <>
                  <StyledStores />

                  <Links>
                    <StyledLegal />
                    <Social />
                  </Links>
                </>
              ) : (
                <>
                  <StyledLegal />

                  <Links>
                    <StyledStores />
                    <Social />
                  </Links>
                </>
              )
            }
          </Media>
          <Company />
        </Bottom>

        <StyledBottle showPromo={showPromo} />
      </Container>
    </Wrapper>
  );
};
