// import React, { useEffect, useLayoutEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { getPromos } from '../api';
import { ReactComponent as CloseIcon } from './img/close.svg';
import Slider from 'react-slick';
import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/core';
import './slick.css';

const Wrapper = styled.footer`
  position: relative;
  display: ${({ mobile }) => (mobile ? 'none' : 'block')};
  height: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;

  @media (max-width: 767px) {
    display: ${({ visible, mobile }) =>
      visible ? (mobile ? 'block' : 'none') : 'none'};
    position: relative;
    z-index: 100;
    height: 80px;
  }
`;

const Close = styled.div`
  display: ${({ mobile }) => (mobile ? 'block' : 'none')};
  position: absolute;
  z-index: 10;
  top: 4px;
  right: 4px;
  padding: 10px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Logo = styled.img`
  position: relative;
  z-index: 1;
  height: 56px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  transition: transform 1.5s ease;

  @media (max-width: 767px) {
    height: 56px;
  }
`;

const Bg = styled.img`
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  transform: scale3d(1.1, 1.1, 1);
  transition: transform 1.5s ease;
`;

const shining = keyframes`
	0% {
    transform: translate3d(-100%, 0, 0);
  }

	100% {
    transform: translate3d(100%, 0, 0);
  }
`;

const shine = css`
  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    display: block;
    transform: translate3d(100%, 0, 0);
    background: linear-gradient(
      60deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.6) 60%,
      rgba(255, 255, 255, 0) 80%
    );
    animation-name: ${shining};
    animation-duration: 5s;
    animation-iteration-count: infinite;
    pointer-events: none;
  }
`;

const scale = css`
  &:hover {
    ${Logo} {
      transform: translate3d(-50%, -50%, 0) scale3d(1.1, 1.1, 1);
    }

    ${Bg} {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const Slide = styled.a`
  display: block;
  position: relative;
  height: 80px;
  overflow: hidden;

  ${({ isAnimated }) => isAnimated && shine}
  ${({ isAnimated }) => isAnimated && scale}

  @media (max-width: 767px) {
    height: 80px;
  }
`;

const cfg = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  className: 'promos-slider',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

export const Promo = ({ visible, mobile, as, togglePromo, ...props }) => {
  // useLayoutEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   objectFitPolyfill();
  // }, []);

  useEffect(() => {
    const savedVal = localStorage.getItem('promo');

    if (mobile && savedVal) {
      const diff = Date.now() - savedVal;
      const oneDay = 60 * 60 * 24;

      if (diff > oneDay) {
        localStorage.removeItem('promo');

        return;
      }

      togglePromo(false);
    }
  }, []);

  const [promos, setPromos] = useState([]);
  useEffect(() => {
    getPromos({}).then(r => {
      setPromos(r.data);
      if (r.data.length === 0) {
        togglePromo && togglePromo(false); // todo: context
      }
    });
  }, []);

  const handleClose = () => {
    if (mobile) {
      togglePromo(false);
      localStorage.setItem('promo', Date.now());
    }
  };

  const showPromos = promos && promos.length > 0;

  return (
    showPromos && (
      <Wrapper as={as || 'footer'} visible={visible} mobile={mobile} {...props}>
        <Close mobile={mobile} onClick={handleClose}>
          <CloseIcon />
        </Close>

        <Slider {...cfg}>
          {promos.map(promo => (
            <Slide
              href={promo.link}
              target="_blank"
              isAnimated={promo.is_animated}
              rel="noopener noreferrer"
              key={promo.id}
            >
              {promo.bg && (
                <Bg src={promo.bg.medium} alt={promo.title} data-object-fit />
              )}
              {promo.logo && <Logo src={promo.logo.original} alt="" />}
            </Slide>
          ))}
        </Slider>
      </Wrapper>
    )
  );
};
