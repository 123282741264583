import React, { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { BtnIcon as Btn } from '../btn';
import plus from './img/plus.svg';
import minus from './img/minus.svg';
import locate from './img/locate.svg';

export const MapControls = styled.aside`
  display: flex;
  flex-direction: column;
`;

export const ZoomIn = forwardRef((props, ref) => (
  <Btn {...props} ref={ref}>
    <img src={plus} alt="" />
  </Btn>
));

export const ZoomOut = forwardRef((props, ref) => (
  <Btn {...props} ref={ref}>
    <img src={minus} alt="" />
  </Btn>
));

export const Locate = forwardRef((props, ref) => (
  <Btn {...props} ref={ref}>
    <img src={locate} alt="" />
  </Btn>
));
