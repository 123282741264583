import React from 'react';
import { rgba } from 'polished';
import styled from '@emotion/styled/macro';
import bg from './img/bg.png';
import close from './img/close.png';
import logo from './img/logo.png';
import photo from './img/photo.png';
import bag from './img/bag.png';
import hashtag from './img/hashtag.png';
import glass from './img/glass.png';

const Overlay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100vw;
  height: 100vh;
  background: ${rgba('#fff', 0.8)};
  overflow: auto;
  display: flex;
  padding: 48px;

  @media (max-width: 767px) {
    padding: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const Modal = styled.article`
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin: auto;
  background: #fe5000 url(${bg}) 50% 100% no-repeat;
  background-size: 100% auto;
  color: #fff;

  @media (max-width: 767px) {
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
`;

const Close = styled.button`
  display: block;
  width: 37px;
  height: 38px;
  padding: 0;
  position: absolute;
  top: 32px;
  right: 32px;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    top: 10px;
    right: 8px;
    width: 24px;
    height: 24px;
  }
`;

const Inner = styled.div`
  padding: 32px;

  @media (max-width: 767px) {
    padding: 40px 24px;
  }
`;

const Header = styled.header`
  display: block;
  margin: 0 0 18px;
  text-align: center;
  text-transform: uppercase;
  font-size: 69px;
  line-height: 1;
  font-weight: 600;
  color: #fe5000;

  span {
    display: inline-block;
    padding: 10px 16px 0;
    background: #fff;
  }

  @media (max-width: 767px) {
    margin-bottom: 8px;
    font-size: 39px;
  }
`;

const Title = styled.header`
  display: block;
  margin: 0 0 32px;
  font-size: 32px;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;

  span {
    display: block;

    &:first-of-type {
      font-size: 45px;
      line-height: 100%;
      font-weight: 600;
    }
  }

  em {
    font-style: normal;
  }

  @media (max-width: 767px) {
    margin-bottom: 18px;
    font-size: 16px;

    span {
      &:first-of-type {
        font-size: 19px;
      }
    }

    em {
      display: block;
    }
  }
`;

const Opts = styled.ul`
  list-style: none;
  display: block;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 767px) {
    max-width: 240px;
  }
`;

const Opt = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 32px;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 300;

  &:last-of-type {
    margin-bottom: 0;
  }

  b {
    font-weight: 400;
    display: block;
  }

  span {
    display: block;
  }

  @media (max-width: 767px) {
    margin-bottom: 28px;
    font-size: 12px;
  }
`;

const Icon = styled.img`
  display: block;
  margin: 0 16px 0 0;
  width: 67px;
  height: 67px;

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`;

const Rules = styled.a`
  display: none;
`;

export const Globus = ({ toggleModal, ...props }) => {
  return (
    <Overlay onClick={() => toggleModal(false)} {...props}>
      <Modal
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Close
          onClick={() => {
            toggleModal(false);
          }}
        >
          <img src={close} alt="" />
        </Close>

        <Inner>
          <Header>
            <span>Участвуйте</span>
          </Header>

          <Title>
            <span>
              В конкурсе <em>«Коктейльный Бум»</em>
            </span>
            <span>во всех гипермаркетах «Глобус»</span>
            <span>с 04 сентября по 24 сентября</span>
          </Title>

          <Opts>
            <Opt>
              <Icon src={logo} />
              <div>
                <b>Найдите оранжевую зону Aperol Spritz</b>
                <span>в гипермаркете «Глобус»</span>
              </div>
            </Opt>

            <Opt>
              <Icon src={photo} />
              <div>
                <b>Сделайте фото на ее фоне и выложите в Instagram</b>
                <span>
                  с указанием геолокации и хештегами #коктейльныйбум и
                  #AperolSpritz
                </span>
              </div>
            </Opt>

            <Opt>
              <Icon src={bag} />
              <div>
                <b>Получите стильную сумку в подарок</b>
                <span />
              </div>
            </Opt>

            <Opt>
              <Icon src={hashtag} />
              <div>
                <b>Не забудьте открыть профиль в соцсетях,</b>
                <span>чтобы организаторы могли связаться с вами</span>
              </div>
            </Opt>

            <Opt>
              <Icon src={glass} />
              <div>
                <b>Узнайте подробности и адреса магазинов</b>
                <span>в полных правилах конкурса</span>
              </div>
            </Opt>
          </Opts>

          <Rules>Узнать правила</Rules>
        </Inner>
      </Modal>
    </Overlay>
  );
};
