export const getValue = (valueToFind, src, key = 'value') => {
  for (let i = 0; i < src.length; i++) {
    let item = src[i];

    if (item[key] === valueToFind) return item;

    if (item.options && item.options.length) {
      let subItem = getValue(valueToFind, item.options);

      if (subItem) return subItem;
    }
  }
};

export const intervalRAF = (callee, delay = 1000) => {
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

  window.requestAnimationFrame = requestAnimationFrame;

  let runTime = new Date().getTime();
  let instance = {};

  const looper = () => {
    const current = new Date().getTime();
    const delta = current - runTime;
    const passed = delta >= delay;

    if (passed) {
      callee.call();
      runTime = new Date().getTime();
    }

    instance.current = requestAnimationFrame(looper);
  };

  callee.call();
  instance.current = requestAnimationFrame(looper);

  return instance;
};

export const cancelIntervalRAF = instance => {
  const cancelAnimationFrame =
    window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.msCancelAnimationFrame;

  if (instance.current) cancelAnimationFrame(instance.current);
};
