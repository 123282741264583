import React, { useState, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from '@emotion/styled/macro';
import { withRouter } from 'react-router-dom';
import { ReactComponent as SearchIcon } from './img/search.svg';
import { ReactComponent as CloseIcon } from './img/close.svg';
import { pushEvent } from '../gtm';

const encodeURIHash = s => {
  return encodeURI(s).replace(/#/g, '%23');
};

const Wrapper = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  width: 100%;
  max-width: ${props => (props.opened ? 'calc(100% - 420px - 20px)' : '40px')};
  transition: all ease 0.3s;

  @media (max-width: 1600px) {
    max-width: ${props =>
      props.opened ? 'calc(100% - 380px - 20px)' : '40px'};
  }

  @media (max-width: 1024px) {
    max-width: ${props => (props.opened ? 'calc(100% - 40px)' : '40px')};
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const OpenSearch = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
  opacity: 0;

  &:checked ~ label {
    justify-content: flex-start;
    width: 100%;
    border-radius: 20px;
    padding-left: 12px;
    background: #fff;
    /* transition: none; */

    svg,
    img {
      filter: none;
    }
  }

  &:checked ~ input[type='search'] {
    z-index: 11;
    width: calc(100% - 40px);
    height: 100%;
    opacity: 1;
  }

  &:checked ~ i {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.3s;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  transition: all ease 0.3s;
  cursor: pointer;

  svg {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    z-index: 1;
  }

  &:hover {
    background: #fe5000;
    color: #fff;

    img,
    svg {
      filter: brightness(0) invert(1);
    }
  }
`;

const Input = styled.input`
  position: absolute;
  top: 50%;
  left: 40px;
  width: 0;
  height: 0;
  padding-left: 4px;
  padding-right: 20px;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
  opacity: 0;
  outline: none;
  transition: opacity ease 0.3s, visibility ease 0.3s;
  transition-delay: 0.4s;
  font-size: 14px;

  &::placeholder {
    color: rgba(8, 48, 75, 0.4);
    line-height: 38px;
    text-transform: uppercase;
  }
`;

const Close = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 14px;
  z-index: 12;
  padding: 4px;
  opacity: 0;
  visibility: hidden;
  transition: ease 0.3s;
  transform: translateY(-50%);

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Component = props => {
  const openerRef = useRef(null);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const [isOpen, setSearch] = useState(false);

  const handleOpenerChange = event => {
    const isChecked = event.target.checked;
    setSearch(isChecked);

    if (isChecked) {
      pushEvent('Клик на поиск');
      inputRef.current.focus();
    }
  };

  const closeSearch = () => {
    setSearch(false);
    openerRef.current.checked = false;
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      openerRef.current.checked = false;
      props.history.push(`/search?q=${encodeURIHash(e.target.value)}`);
    }
  };

  useOnClickOutside(wrapperRef, closeSearch);

  return (
    <Wrapper ref={wrapperRef} opened={isOpen} {...props}>
      <OpenSearch
        ref={openerRef}
        type="checkbox"
        id="open-search"
        onChange={handleOpenerChange}
      />

      <Label htmlFor="open-search">
        <SearchIcon />
      </Label>

      <Input
        ref={inputRef}
        id="search"
        type="search"
        placeholder="ПОИСК..."
        onKeyPress={handleKeyPress}
      />

      <Close onClick={closeSearch}>
        <CloseIcon />
      </Close>
    </Wrapper>
  );
};

export const Search = withRouter(Component);
