import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as MenuIcon } from './img/menu.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const MenuBtn = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  position: ${({ alternative }) => (alternative ? 'absolute' : 'fixed')};
  top: 20px;
  left: ${({ alternative }) => (alternative ? null : '20px')};
  right: ${({ alternative }) => (alternative ? '10px' : null)};
  z-index: 100;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: ${({ alternative }) => (alternative ? '#fff' : '#fc5001')};
  transition: left ease-in-out 1s, background-color ease-in-out 0.3s;

  svg {
    position: relative;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Menu = ({ alternative, toggleSidebar, ...props }) => {
  return (
    <MenuBtn alternative={alternative} onClick={toggleSidebar} {...props}>
      {alternative ? <ArrowIcon /> : <MenuIcon />}
    </MenuBtn>
  );
};
