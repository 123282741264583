import React, { useState, useEffect, useContext } from 'react';
import { Aperol } from '../context';
import styled from '@emotion/styled/macro';
import { getParty } from '../api';
import { About, Heading } from '../typo';
import { Back } from '../back';
import { PartySlider } from './PartySlider';
import Share from '../share';

const Wrapper = styled.section`
  position: relative;
  z-index: 10;
  padding: 30px 0 40px;
  background: #fff;
  min-height: 100vh;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
`;

const Content = styled.div`
  padding: 0 20px;

  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

const Date = styled.span`
  display: block;
  margin-bottom: 8px;
  color: rgba(8, 48, 75, 0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Address = styled.p`
  margin: 0;
  color: #08304b;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
`;

const SliderWrapper = styled.div`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`;

const Bottom = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Download = styled.div`
  display: flex;
  align-items: center;

  span {
    color: rgba(0, 0, 0, 0.4);
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 130%;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const DownloadLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-right: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 14px;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #fc5001;
  transition: ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 байтов';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['байт', 'Кб', 'Мб', 'Гб'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const Party = ({ location, match, ...props }) => {
  const { methods } = useContext(Aperol);
  const { toggleFrontView, toggleSearch } = methods;

  useEffect(() => {
    toggleFrontView(false);
    toggleSearch(false);

    return () => {
      toggleFrontView(true);
      toggleSearch(true);
    };
  }, []);

  const [party, setParty] = useState(null);
  const { id } = match.params;

  const showGallery =
    party &&
    party.gallery &&
    party.gallery.data &&
    party.gallery.data.length > 0;

  useEffect(() => {
    const fetchData = () => {
      getParty(id, { include: 'city,gallery' }).then(party => setParty(party));
    };

    fetchData();
  }, [id]);

  return (
    <Wrapper {...props}>
      {party && (
        <Content>
          <Back to="/parties" />

          {party.time && <Date>{party.time}</Date>}

          <Heading>{party.title}</Heading>

          <Info>
            <Address>
              {party.place &&
                (party.city.data.name ? party.place + ', ' : party.place)}
              {party.city.data.name &&
                (party.address
                  ? party.city.data.name + ', '
                  : party.city.data.name)}
              {party.address && party.address.replace(/,/g, '')}
            </Address>
          </Info>

          {showGallery ? (
            <SliderWrapper>
              <PartySlider id={party.id} slides={party.gallery.data} />
            </SliderWrapper>
          ) : party.cover ? (
            <SliderWrapper>
              <PartySlider cover={party.cover} />
            </SliderWrapper>
          ) : null}

          <About>
            <article
              dangerouslySetInnerHTML={{
                __html: party.desc ? party.desc : party.short,
              }}
            />
          </About>

          <Bottom>
            {party.archive && (
              <Download>
                <DownloadLink href={party.archive.path}>Скачать</DownloadLink>
                <span>{formatBytes(party.archive.file_size)}, .zip</span>
              </Download>
            )}

            <Share title={`${party.title}, ${party.place}`} />
          </Bottom>
        </Content>
      )}
    </Wrapper>
  );
};
