import React, { Component } from 'react';
import ScrollMemory from 'react-router-scroll-memory';
import { Route } from 'react-router-dom';
import { UsedRouter as Router } from './router';
import { format, addDays } from 'date-fns';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { getCitiesGeocode, getMapPoints, getSettings } from './api';
import { Provider } from './context';
import { Age, Welcome, NextSummer, Sorry, Off, Loading } from './welcome';
import { Map } from './map';
import { List } from './list';
import { Panel } from './panel';
import { Search } from './search';
import { Switch, SwitchMobile } from './switch';
import { Filter } from './filter';
import { Sidebar } from './sidebar';
import { Header } from './header';
import { Menu } from './menu';
import { SearchPage } from './search-page';
import { Point } from './point';
import { Parties } from './parties';
import { Party } from './party';
import { HowTo } from './how-to';
import { Feedback } from './feedback';
import { Rotate } from './rotate';
import { pushEvent } from './gtm';
import { Globus } from './globus';

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const AP = window.AP;

const uniqPoints = arr => {
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    const key = `${arr[i].lat}${arr[i].lng}`;
    obj[key] = arr[i];
  }

  return Object.values(obj);
};

const Container = styled.section`
  margin: 0 0 0 400px;

  @media (max-width: 1600px) {
    margin-left: 360px;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    padding-top: ${({ showPromo }) => (showPromo ? '140px' : '60px')};
  }
`;

const Inner = styled.div`
  position: relative;
`;

const FrontView = styled.section`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const { location } = window;

const createCookie = (name, value, days) => {
  let date = null;
  let expires = '';

  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  }

  document.cookie = name + '=' + value + expires + '; path=/';
};

// const getCookie = name => {
//   const value = '; ' + document.cookie;
//   const parts = value.split('; ' + name + '=');

//   if (parts.length === 2) {
//     return parts
//       .pop()
//       .split(';')
//       .shift();
//   }

//   return false;
// };

class App extends Component {
  state = {
    city: {
      // id: 6410,
      // lat: 55.755826,
      // lng: 37.6172999,
      // name: 'Москва',
      id: null, // TODO: wtf with default?
    },
    mapPoints: [],
    view: 'map',
    filter: '',
    dateFilter: 'all',
    date: new Date(),
    isSidebarOpen: false,
    isFrontView: true,
    isLoading: true,
    showSearch: true,
    showAgeGate: !!+process.env.REACT_APP_AGE,
    showSorry: !!+process.env.REACT_APP_AGE,
    // showGlobus: !!!getCookie('hide-globus') || location.hash === '#globus',
    showGlobus: false,
    showWelcome:
      !!+process.env.REACT_APP_WELCOME &&
      (location.pathname === '/' || !!+process.env.REACT_APP_CORDOVA),
    showNextSummer: true,
    showPromo: true,
    settings: null,
  };

  componentDidMount() {
    localStorage.getItem('switch') &&
      this.setState({
        view: localStorage.getItem('switch'),
      });

    const { filter, date } = this.state;
    const from = format(date, 'YYYY-MM-DD');

    getSettings().then(settings => {
      return getCitiesGeocode().then(city => {
        return getMapPoints(city.id, { icon: filter, from }).then(mapPoints => {
          this.setState({
            settings,
            mapPoints,
            city,
            isLoading: false,
          });
        });
      });
    });
  }

  componentDidUpdate(_, prevState) {
    const { city, filter, date } = this.state;
    const from = format(date, 'YYYY-MM-DD');

    if (
      prevState.city.id !== city.id ||
      prevState.filter !== filter ||
      prevState.date !== date
    ) {
      getMapPoints(city.id, { icon: filter, from }).then(mapPoints => {
        const points = uniqPoints(mapPoints);
        this.setState({
          mapPoints: points,
        });
      });
    }
  }

  getCity = () => {
    return this.state.city;
  };

  switchFilter = type => {
    const { filter } = this.state;

    if (filter === type) {
      return;
    }

    AP.directionsDisplay.setMap(null);
    this.setState({ filter: type });
  };

  switchDateFilter = type => {
    const { dateFilter } = this.state;

    if (dateFilter === type) {
      return;
    }

    this.setState({ dateFilter: type });
  };

  switchView = type => {
    const { view } = this.state;

    if (view === type && localStorage.getItem('switch') === type) {
      return;
    }

    this.setState({ view: type });
    localStorage.setItem('switch', type);
  };

  setDate = newDate => {
    const { date } = this.state;

    if (newDate === date || addDays(newDate, 1) < new Date()) {
      return;
    }

    pushEvent('Клик на календарь');

    this.setState({ date: newDate });
  };

  toggleSidebar = () => {
    this.setState(state => {
      return {
        isSidebarOpen: !state.isSidebarOpen,
      };
    });
  };

  toggleFrontView = val => {
    this.setState({
      isFrontView: val,
    });
  };

  toggleSearch = val => {
    this.setState({
      showSearch: val,
    });
  };

  setCity = city => {
    this.setState({
      city,
    });
  };

  togglePromo = val => {
    this.setState({
      showPromo: val,
    });
  };

  toggleGlobus = val => {
    this.setState(
      {
        showGlobus: val,
      },
      () => {
        if (val === false) {
          createCookie('hide-globus', 'yes', 2);
          location.hash = '';
        }
      }
    );
  };

  handleAgeAnswer = answer => {
    this.setState({
      showAgeGate: false,
      showSorry: false,
    });

    if (!answer) {
      this.setState({
        showSorry: true,
      });
    }
  };

  render() {
    const {
      setDate,
      toggleSidebar,
      setCity,
      switchView,
      toggleFrontView,
      toggleSearch,
      switchFilter,
      switchDateFilter,
      togglePromo,
      toggleGlobus,
      getCity,
      handleAgeAnswer,
    } = this;

    const {
      city,
      mapPoints,
      view,
      filter,
      date,
      showPromo,
      isSidebarOpen,
      isFrontView,
      isLoading,
      showSearch,
      showAgeGate,
      showWelcome,
      showNextSummer,
      showSorry,
      showGlobus,
      settings,
    } = this.state;

    const from = format(date, 'YYYY-MM-DD');

    return (
      <Provider
        value={{
          state: this.state,
          methods: {
            setDate,
            switchView,
            toggleFrontView,
            toggleSearch,
            switchFilter,
            switchDateFilter,
            toggleSidebar,
            togglePromo,
            getCity,
          },
        }}
      >
        <Router>
          <ScrollMemory />

          <Sidebar
            isOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            setCity={setCity}
            count={mapPoints.length}
          />

          <Container isSidebarOpen={isSidebarOpen} showPromo={showPromo}>
            <Inner>
              <Header toggleSidebar={toggleSidebar} />
              <Menu toggleSidebar={toggleSidebar} />
              <Search isVisible={showSearch} />

              <Map city={city} points={mapPoints} showPromo={showPromo} />

              <FrontView isVisible={isFrontView}>
                {settings && (
                  <Filter
                    isSidebarOpen={isSidebarOpen}
                    disableGlobus={!!settings.globus_disable}
                    map
                  />
                )}

                <Switch view={view} switchView={switchView} />
                <SwitchMobile view={view} switchView={switchView} />

                <List city={city} view={view} from={from} icon={filter} />

                <Panel showPromo={showPromo} />
              </FrontView>

              <Route path="/search" component={SearchPage} />
              <Route path="/points/:id" component={Point} />
              <Route exact path="/parties" component={Parties} />
              <Route path="/parties/:id" component={Party} />
              <Route path="/feedback" component={Feedback} />
              <Route path="/how-to" component={HowTo} />
            </Inner>
          </Container>

          {showWelcome && (
            <Welcome
              answerHandler={icon => {
                this.switchFilter(icon);
                this.setState({ showWelcome: false });
              }}
            />
          )}

          {showSorry && <Sorry />}

          {(showWelcome ||
            showAgeGate ||
            showSorry ||
            showNextSummer ||
            (isFrontView && view === 'map')) && (
            <Global
              styles={css`
                body {
                  position: fixed;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  overflow: hidden;
                }
              `}
            />
          )}

          {showAgeGate && <Age answerHandler={handleAgeAnswer} />}

          {showNextSummer && <NextSummer />}

          {isLoading && <Loading />}

          {settings &&
            !!+settings.demo_app_is_off &&
            !!+process.env.REACT_APP_CORDOVA && (
              <Off
                stub={
                  settings.demo_stub_text || 'Приложение временно недоступно'
                }
              />
            )}

          {isFrontView && <Rotate />}

          {showGlobus && <Globus toggleModal={toggleGlobus} />}
        </Router>
      </Provider>
    );
  }
}

export default App;
