import React, { useState, useContext, useEffect, useRef } from 'react';
import { Aperol } from '../context';
import { getParties } from '../api';
import styled from '@emotion/styled/macro';
import { PartiesFilter } from './PartiesFilter';
import { Heading } from '../typo';
import { Btn } from '../btn';
import { Card } from '../card';
import { pushEvent } from '../gtm';

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const Wrapper = styled.section`
  position: relative;
  z-index: 10;
  padding: 80px 0 40px;
  min-height: 100vh;
  background: #fff;

  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const Content = styled.div`
  padding: 0 20px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-right: 10px;
    padding-left: 10px;
  }
`;

const Cards = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  > article {
    width: calc((100% / 3) - (60px / 3));
    margin-right: 20px;
    margin-bottom: 20px;
    text-decoration: none;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const Parties = props => {
  const { methods } = useContext(Aperol);
  const { toggleFrontView, toggleSearch } = methods;

  useEffect(() => {
    toggleFrontView(false);
    toggleSearch(false);

    return () => {
      toggleFrontView(true);
      toggleSearch(true);
    };
  }, []);

  const [parties, setParties] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [period, setPeriod] = useState('all');
  const prevPeriod = usePrevious(period);

  const switchParties = period => {
    setPeriod(period.value);
  };

  useEffect(() => {
    const isPeriodChanged = period !== prevPeriod;

    getParties({
      include: 'city',
      period,
      page,
    }).then(r => {
      setParties(isPeriodChanged ? r.data : [...parties, ...r.data]);
      setMeta(r.meta);
    });
  }, [page, period]);

  const showParties = parties && parties.length > 0;
  const showMore =
    meta && meta.pagination.current_page < meta.pagination.total_pages;

  return (
    <Wrapper {...props}>
      <PartiesFilter period={period} handleChange={switchParties} />

      <Content>
        <Heading>
          Лучшее, что случилось
          <span> этим летом</span>
        </Heading>

        {showParties && (
          <>
            <Cards>
              {parties.map(item => (
                <Card
                  entity={item}
                  type="party"
                  gtm={() => pushEvent('Клик на фотоальбом')}
                  key={item.id}
                />
              ))}
            </Cards>

            {showMore && (
              <Btn
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                Показать больше
              </Btn>
            )}
          </>
        )}
      </Content>
    </Wrapper>
  );
};
