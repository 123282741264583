import React, { useState } from 'react';
import Datepicker from 'react-date-picker';
// import { pushEvent } from '../gtm';
import { ReactComponent as CalendarIcon } from './img/calendar.svg';
import { ReactComponent as CloseIcon } from './img/close.svg';
import { ReactComponent as LeftArrow } from './img/arrow-left.svg';
import { ReactComponent as RightArrow } from './img/arrow-right.svg';
import './index.css';

export default props => {
  const { date, setDate } = props;
  const [calendarState, setCalendarState] = useState(false);
  const lang = 'ru';

  const handleChange = value => {
    // pushEvent('Клик на календарь');
    setDate(value);
  };

  const formatDate = date => {
    return `${new Intl.DateTimeFormat(lang, {
      month: 'long',
    }).format(date)} ${date.getFullYear()}`;
  };

  const handleCalendarOpen = () => {
    setCalendarState(true);
  };

  const handleCalendarClose = () => {
    setCalendarState(false);
  };

  return (
    <Datepicker
      value={date}
      minDate={new Date()}
      onChange={value => handleChange(value)}
      onCalendarOpen={handleCalendarOpen}
      onCalendarClose={handleCalendarClose}
      locale={lang}
      calendarIcon={calendarState ? <CloseIcon /> : <CalendarIcon />}
      className="ap-datepicker"
      minDetail="month"
      navigationLabel={({ date }) => formatDate(date)}
      prevLabel={<LeftArrow />}
      nextLabel={<RightArrow />}
    />
  );
};
