export default {
  id: 55727,
  lat: 60.167701,
  lng: 29.82311,
  from: '30.04.2019',
  to: '30.09.2019',
  city: {
    data: {
      id: 6402,
      lat: 59.9342802,
      lng: 30.3350986,
      name: 'Город',
    },
  },
  icon: 1,
  type: 0,
  title: 'Название',
  cover: {
    original: 'https://dummyimage.com/2560x1440/ccc/fff',
    small: 'https://dummyimage.com/640x360/ccc/fff',
    medium: 'https://dummyimage.com/640x360/ccc/fff',
    large: 'https://dummyimage.com/1280x720/ccc/fff',
    thumb: 'https://dummyimage.com/640x360/ccc/fff',
  },
  gallery: {
    data: [
      {
        original: 'https://dummyimage.com/2560x1440/ccc/fff',
        small: 'https://dummyimage.com/640x360/ccc/fff',
        medium: 'https://dummyimage.com/640x360/ccc/fff',
        large: 'https://dummyimage.com/1280x720/ccc/fff',
        thumb: 'https://dummyimage.com/640x360/ccc/fff',
      },
      {
        original: 'https://dummyimage.com/2560x1440/ccc/fff',
        small: 'https://dummyimage.com/640x360/ccc/fff',
        medium: 'https://dummyimage.com/640x360/ccc/fff',
        large: 'https://dummyimage.com/1280x720/ccc/fff',
        thumb: 'https://dummyimage.com/640x360/ccc/fff',
      },
    ],
  },
  place: 'Место',
  address: 'Адрес',
  short: 'Краткое описание',
  desc: 'Полное описание',
  link: 'https://google.ru/',
  whpd: 'Рабочие часы',
  time: 'Время начала',
  phone: '+7 999 99-99-99',
  photos: 20,
};
