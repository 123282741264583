export default {
  control: predefined => ({
    ...predefined,
    zIndex: '1',
    minHeight: '40px',
    border: 'none',
    boxShadow: null,
    borderRadius: '0 20px 20px 0',
    paddingLeft: '10px',
    backgroundColor: '#fff',
    '&:hover': {
      cursor: 'pointer',
      borderColor: 'hsl(0,0%,80%)',
    },
    '@media (max-width: 1024px)': {
      borderRadius: '20px',
    },
    '@media (max-width: 768px)': {
      borderRadius: '0 20px 20px 0',
    },
  }),
  dropdownIndicator: (predefined, state) => ({
    ...predefined,
    paddingRight: '20px',

    svg: {
      transition: 'transform 0.3s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : null,
    },
  }),
  group: predefined => ({
    ...predefined,
    position: 'relative',
    marginBottom: '10px',
    paddingTop: null,
    paddingBottom: '10px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '44px',
      width: 'calc(100% - 80px)',
      height: '1px',
      backgroundColor: 'rgba(8, 48, 75, 0.1)',
    },
  }),
  groupHeading: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: predefined => ({
    ...predefined,
    position: 'absolute',
    display: 'none',
  }),
  menu: predefined => ({
    ...predefined,
    top: '0',
    zIndex: '-1',
    margin: '0',
    paddingTop: '50px',
    borderRadius: '20px',
  }),
  menuList: predefined => ({
    ...predefined,
    paddingTop: '0',
    paddingBottom: '17px',
  }),
  option: (predefined, state) => ({
    ...predefined,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    padding: null,
    paddingRight: '20px',
    paddingLeft: '10px',
    color: state.isSelected ? '#08304b' : 'rgba(8, 48, 75, 0.4)',
    fontSize: '14px',
    lineHeight: '120%',
    textTransform: 'uppercase',
    backgroundColor: 'transparent',

    '&:hover': {
      color: '#08304b',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      svg: {
        opacity: '1',
      },
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      color: '#08304b',
      svg: {
        opacity: '1',
      },
    },
    '&:first-child': {
      paddingTop: '0',
    },
    '&:last-child': {
      marginBottom: '0',
      paddingBottom: '0',
    },
    svg: {
      opacity: state.isSelected ? '1' : '0.4',
    },
  }),
  singleValue: predefined => ({
    ...predefined,
    display: 'flex',
    alignItems: 'center',
    position: 'static',
    maxWidth: null,
    margin: '0',
    color: '#08304b',
    fontSize: '14px',
    lineHeight: '120%',
    textTransform: 'uppercase',
    transform: null,
  }),
  valueContainer: predefined => ({
    ...predefined,
    alignItems: 'center',
    height: '40px',
    padding: '0',

    input: {
      position: 'absolute',
    },
  }),
};
