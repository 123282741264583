import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API + '/api/v1/',
});

const encodeURIHash = s => {
  return encodeURI(s)
    .replace(/#/g, '%23')
    .replace(/\+/g, '%2B');
};

const buildEndpoint = (path, qp) => {
  const prefix = path.includes('?') ? '' : '?';
  const query =
    qp &&
    Object.keys(qp).map(key => {
      return `&${key}=${encodeURIHash(qp[key])}`;
    });
  return qp ? path + prefix + query.join('') : path;
};

export const getSettings = qp => {
  const endpoint = buildEndpoint('/settings', qp);
  return api(endpoint).then(({ data }) => data.data);
};

export const getCities = () => {
  return api('/cities').then(({ data }) => data.data);
};

export const getCitiesGeocode = () => {
  return api('/cities/geocode').then(({ data }) => data.data);
};

export const getCity = id => {
  if (!id) {
    throw new Error("[Aperol API Handler] Argument 'id' is required!");
  }

  return api(`/cities/${id}`).then(({ data }) => data.data);
};

export const getPoints = (city_id, qp) => {
  if (!city_id) {
    throw new Error("[Aperol API Handler] Argument 'city_id' is required!");
  }

  const endpoint = buildEndpoint(`/points/?city=${city_id}`, qp);
  return api(endpoint).then(({ data }) => data);
};

export const getPoint = (id, qp) => {
  if (!id) {
    throw new Error("[Aperol API Handler] Argument 'id' is required!");
  }

  const endpoint = buildEndpoint(`/points/${id}`, qp);
  return api(endpoint).then(({ data }) => data.data);
};

export const postPointsSearch = (city_id, qp) => {
  if (!city_id) {
    throw new Error("[Aperol API Handler] Argument 'city_id' is required!");
  }

  const endpoint = buildEndpoint(`/points/search?city=${city_id}`, qp);
  return api.post(endpoint).then(({ data }) => data);
};

export const getMapPoints = (city_id, qp) => {
  if (!city_id) {
    throw new Error("[Aperol API Handler] Argument 'city_id' is required!");
  }

  const endpoint = buildEndpoint(`/map/points/?city=${city_id}`, qp);
  return api(endpoint).then(({ data }) => data.data);
};

export const getParties = qp => {
  const endpoint = buildEndpoint('/parties', qp);
  return api(endpoint).then(({ data }) => data);
};

export const getParty = (id, qp) => {
  if (!id) {
    throw new Error("[Aperol API Handler] Argument 'id' is required!");
  }

  const endpoint = buildEndpoint(`/parties/${id}`, qp);
  return api(endpoint).then(({ data }) => data.data);
};

export const getPromos = qp => {
  const endpoint = buildEndpoint('/promos', qp);
  return api(endpoint).then(({ data }) => data);
};

export const getFeedbackTopics = qp => {
  const endpoint = buildEndpoint('/feedback/topics', qp);
  return api(endpoint).then(({ data }) => data.data);
};

export const postFeedback = qp => {
  const endpoint = buildEndpoint('/feedback', qp);
  return api
    .post(endpoint)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};
