import supercluster from 'points-cluster';
import React, { forwardRef, Component } from 'react';
import { format } from 'date-fns';
import { getMapPoints } from '../api';
import GoogleMapReact from 'google-map-react';
import styled from '@emotion/styled/macro';
import { Marker, Cluster } from '../marker';
import styles from './styles';

const APE = window.APE;

const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const Wrapper = styled.section`
  height: 600px;

  @media (max-width: 767px) {
    height: 400px;
  }
`;

class Map extends Component {
  constructor(props) {
    super(props);

    const { lat, lng } = props.point;

    this.state = {
      clusters: [],
      points: [],
      options: {
        // center: { lat: 55.95218717795308, lng: 37.66774151914001 },
        center: { lat, lng },
        zoom: 16,
        bounds: null,
      },
    };

    this.handleMapChange = this.handleMapChange.bind(this);
  }

  componentDidMount() {
    const from = format(new Date(), 'YYYY-MM-DD');
    const { city, title, place } = this.props.point;

    getMapPoints(city.data.id, { title, place, from }).then(points => {
      this.setState(
        {
          points,
        },
        () => {
          this.createClusters();
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.points !== prevProps.points) {
      this.createClusters();
    }
  }

  handleRoute(point) {
    const { points } = this.state;
    const prevPoints = points;

    this.setState({ points: [point], prevPoints }, () => {});
  }

  handleRouteCancel() {
    const { prevPoints } = this.state;

    this.setState({ points: prevPoints, prevPoints: [] }, () => {
      this.createClusters();
    });
  }

  getClusters = () => {
    const { points, options } = this.state;

    const clusters = supercluster(points);

    return clusters(options);
  };

  createClusters = () => {
    const { options } = this.state;

    this.setState({
      clusters: options.bounds
        ? this.getClusters().map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
            points,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    const { state } = this;
    const { points } = state;

    this.setState(
      {
        ...state,
        options: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        if (points.length > 1 || points.length === 0) {
          this.createClusters();
        }
      }
    );
  };

  handleApiLoaded = (map, maps) => {
    APE.map = map;
    APE.maps = maps;

    APE.directionsDisplay = new maps.DirectionsRenderer();
    APE.directionsDisplay.setOptions({
      preserveViewport: true,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#fc5001',
        strokeWeight: 3,
      },
    });

    maps.Map.prototype.panToWithOffset = function(latlng, offsetX, offsetY) {
      const overlay = new maps.OverlayView();
      const map = this;

      overlay.onAdd = function() {
        const proj = this.getProjection();
        const aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x = aPoint.x + offsetX;
        aPoint.y = aPoint.y + offsetY;
        map.panTo(proj.fromContainerPixelToLatLng(aPoint));
      };

      overlay.draw = function() {};
      overlay.setMap(map);
    };

    map.setOptions({
      styles,
      disableDefaultUI: true,
      clickableIcons: false,
      gestureHandling: 'cooperative',
    });
  };

  render() {
    const { clusters, options, points } = this.state;
    const { point } = this.props;
    const { lat, lng } = point;

    return (
      <Wrapper ref={this.props.innerRef}>
        <GoogleMapReact
          bootstrapURLKeys={{ key }}
          center={{ lat, lng }}
          zoom={options.zoom}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
          {points.length > 1 || points.length === 0 ? (
            clusters.map(item => {
              if (item.numPoints === 1) {
                return (
                  <Marker
                    key={item.id}
                    id={item.points[0].id}
                    lat={item.points[0].lat}
                    lng={item.points[0].lng}
                    thisPoint={item.points[0]}
                    handleRoute={point => this.handleRoute(point)}
                    handleRouteCancel={() => this.handleRouteCancel()}
                    type="EMBED"
                  />
                );
              }

              return (
                <Cluster
                  key={item.id}
                  lat={item.lat}
                  lng={item.lng}
                  type="EMBED"
                  points={item.points}
                />
              );
            })
          ) : (
            <Marker
              key={points[0].id}
              id={points[0].id}
              lat={points[0].lat}
              lng={points[0].lng}
              thisPoint={points[0]}
              isInRoute={points[0].travelMode}
              type="EMBED"
              handleRoute={point => this.handleRoute(point)}
              handleRouteCancel={() => this.handleRouteCancel()}
            />
          )}
        </GoogleMapReact>
      </Wrapper>
    );
  }
}

export const MapEmbed = forwardRef((props, ref) => (
  <Map innerRef={ref} {...props} />
));
