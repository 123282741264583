import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import { ReactComponent as DriveIcon } from './img/drive.svg';
import { ReactComponent as WalkIcon } from './img/walk.svg';
import { ReactComponent as CloseIcon } from './img/close.svg';

const Tooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 52px + 16px);
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2000;
  display: ${({ active }) => (active ? 'block' : 'none')};
  padding-bottom: 10px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 60px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 20px;
  height: 10px;
  transform: translateX(-50%);
`;

const Rim = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  background-color: #fc5001;

  svg {
    filter: brightness(0) invert(1);
  }
`;

const Close = styled(CloseIcon)`
  margin: 0 0 0 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const RouteTooltip = ({ travelMode, closeHandler }) => {
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    !isActive && closeHandler();
  }, [isActive]);

  return (
    <Tooltip active={isActive}>
      <Inner>
        <Rim>{travelMode === 'WALKING' ? <WalkIcon /> : <DriveIcon />}</Rim>
        <Close onClick={() => setActive(false)} />
      </Inner>

      <Arrow />
    </Tooltip>
  );
};
