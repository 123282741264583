import React from 'react';
import styled from '@emotion/styled/macro';

const AP = window.AP;
const APE = window.APE;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 10px solid #fc5001;
  border-radius: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
`;

export const Cluster = ({ points, type, lat, lng, ...props }) => {
  const NS = type === 'EMBED' ? APE : AP;

  const handleClick = () => {
    NS.map.panTo(new NS.maps.LatLng(lat, lng));
    NS.map.setZoom(NS.map.getZoom() + 1);
  };

  return (
    <Circle {...props} onClick={handleClick}>
      <span>{points.length}</span>
    </Circle>
  );
};
