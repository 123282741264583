import React, { useEffect, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';
import { Aperol } from '../context';
import { postFeedback } from '../api';
import { pushEvent } from '../gtm';
import { Heading } from '../typo';
import { Btn } from '../btn';
import { FeedbackTopics as Topics } from './FeedbackTopics';
import 'react-toastify/dist/ReactToastify.css';

const key = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
const isCordova = !!+process.env.REACT_APP_CORDOVA;

const inputStyle = css`
  display: block;
  font-size: 14px;

  &::placeholder {
    color: rgba(8, 48, 75, 0.4);
  }
`;

const Wrapper = styled.section`
  position: relative;
  /* display: flex; */
  /* justify-content: flex-start; */
  z-index: 10;
  padding: 20px 0 0;
  min-height: 100vh;
  background: #fff;

  @media (max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100 - 100px + 60px);
  }
`;

const Content = styled.section`
  padding: 0 20px;
  /* margin: auto 0; */

  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

const Group = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Tip = styled.span`
  display: block;
  margin-bottom: 30px;
  width: 100%;
  max-width: 830px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 145%;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const StyledBtn = styled(Btn)`
  margin: 0;
  background: #fc5001;
  color: #fff;
  text-transform: none;

  &:hover {
    color: #000;
    background: #fff;
  }
`;

const Label = styled.label`
  position: relative;
  display: block;
  margin: 0;
  padding: 0;

  &:first-of-type:not(:only-of-type) {
    margin-right: 20px;
  }

  @media (max-width: 767px) {
    &:first-of-type:not(:only-of-type) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const Caption = styled.span`
  display: block;
  /* margin: 0 0 10px 16px; */
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 120%;
  color: #fc5001;
`;

const StyledTopics = styled(Topics)`
  width: 350px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Input = styled(Field)`
  ${inputStyle}
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  width: 350px;

  &::placeholder {
    line-height: 38px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Message = styled.div`
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 8px 24px;
  width: 720px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Textarea = styled(Field)`
  ${inputStyle}
  display: block;
  border: none;
  padding: 0;
  width: 100%;
  height: 68px;
  resize: none;
  -ms-overflow-style: none;
  scrollbar-color: #000000 #efefef;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: #efefef;
  }
`;

const Checkbox = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: ${({ error }) => (error ? '#cc0000' : '#000000')};
  cursor: pointer;

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;

    &:checked {
      + i {
        &::before {
          background-color: #08304b;
        }
      }
    }
  }

  i {
    flex-shrink: 0;
    position: relative;
    display: block;
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;
    border: 1px solid #08304b;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      background: transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s ease;
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: #fc5001;
    }
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

const Error = styled(ErrorMessage)`
  display: block;
  margin: 6px 0 0;
  font-size: 12px;
  color: #cc0000;
  font-style: normal;
`;

const handleValidate = values => {
  const required = ['topic', 'name', 'email', 'message', 'is_agreed'];

  if (!isCordova) {
    required.push('captcha');
  }

  let errors = {};

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Неверный e-mail';
  }

  required.forEach(item => {
    if (!values[item]) {
      errors[item] = 'Обязательно к заполнению';
    }
  });

  return errors;
};

export const Feedback = props => {
  const { methods, state } = useContext(Aperol);
  const { toggleFrontView, toggleSearch } = methods;
  const { settings } = state;

  useEffect(() => {
    toggleFrontView(false);
    toggleSearch(false);

    return () => {
      toggleFrontView(true);
      toggleSearch(true);
    };
  }, []);

  // console.log('render feedback');

  const showPrivacyPolicy = settings && settings.privacy_policy;

  return (
    <Wrapper {...props}>
      <Content>
        <Heading>Связаться с нами</Heading>

        <Tip>
          Установленный срок предоставления ответа по запросу через форму
          обратной связи — 3 рабочих дня. Решение вопроса может занимать до 5
          рабочих дней с момента подтверждения полученного запроса. В
          нестандартных случаях, требующих дополнительной обработки, время может
          быть увеличено на неопределенный срок и устанавливается индивидуально.
        </Tip>

        <Formik
          initialValues={{
            topic: null,
            name: '',
            email: '',
            message: '',
            is_agreed: false,
            captcha: false,
          }}
          validate={handleValidate}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.is_agreed = values.is_agreed && 1;
            postFeedback(values)
              .then(() => {
                pushEvent('Сообщение успешно отправлено');
                toast.success('Сообщение отправлено!');
                resetForm({
                  topic: values.topic,
                  name: '',
                  email: '',
                  message: '',
                  is_agreed: false,
                  captcha: values.captcha,
                });
              })
              .catch(() => {})
              .then(() => {
                setSubmitting(false);
              });
          }}
          render={({ values, errors, isSubmitting, setFieldValue }) => (
            <Form>
              <Group>
                <Label>
                  <Caption>Выберите тему</Caption>
                  <StyledTopics
                    handleChange={topic => setFieldValue('topic', topic)}
                  />
                  <Error name="topic" component="i" />
                </Label>
              </Group>

              <Group>
                <Label>
                  <Caption>Имя</Caption>
                  <Input type="text" name="name" placeholder="Напишите имя" />
                  <Error name="name" component="i" />
                </Label>

                <Label>
                  <Caption>E-mail</Caption>
                  <Input
                    type="text"
                    name="email"
                    placeholder="Введите E-mail"
                  />
                  <Error name="email" component="i" />
                </Label>
              </Group>

              <Group>
                <Label>
                  <Caption>Сообщение</Caption>
                  <Message>
                    <Textarea
                      name="message"
                      placeholder="Введите сообщение"
                      component="textarea"
                    />
                  </Message>
                  <Error name="message" component="i" />
                </Label>
              </Group>

              {!isCordova && (
                <Group>
                  <Label>
                    <ReCAPTCHA
                      sitekey={key}
                      value={values.captcha}
                      onChange={value => setFieldValue('captcha', value)}
                    />
                    <Error name="captcha" component="i" />
                  </Label>
                </Group>
              )}

              <Group>
                <Checkbox error={errors.is_agreed ? 'error' : ''}>
                  <Field
                    type="checkbox"
                    name="is_agreed"
                    checked={values.is_agreed}
                  />
                  <i />
                  {showPrivacyPolicy ? (
                    <div>
                      Подтверждаю согласие на обработку персональных данных в
                      соответствии с{' '}
                      <a
                        href={settings.privacy_policy.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Политикой конфиденциальности
                      </a>
                    </div>
                  ) : (
                    <div>
                      Подтверждаю согласие на обработку персональных данных в
                      соответствии с Политикой конфиденциальности
                    </div>
                  )}
                </Checkbox>
              </Group>

              <Group>
                <StyledBtn type="submit" disabled={isSubmitting}>
                  Отправить
                </StyledBtn>
              </Group>
            </Form>
          )}
        />
      </Content>

      <ToastContainer position="top-center" />
    </Wrapper>
  );
};
