import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as AperolIcon } from './img/aperol.svg';
import { ReactComponent as OrangeIcon } from './img/orange.svg';
import { ReactComponent as StarIcon } from './img/star.svg';
import { ReactComponent as ShakerIcon } from './img/shaker.svg';
import { ReactComponent as CartIcon } from './img/cart.svg';
import { ReactComponent as CocktailIcon } from './img/cocktail.svg';
import { ReactComponent as TerraceIcon } from './img/terrace.svg';
import { ReactComponent as GlobeIcon } from './img/globe.svg';

const Orange = styled(OrangeIcon)`
  width: 18px;
  height: 18px;
`;

const Star = styled(StarIcon)`
  width: 22px;
  height: 20px;
`;

const Shaker = styled(ShakerIcon)`
  width: 10px;
  height: 18px;
`;

const Cart = styled(CartIcon)`
  width: 18px;
  height: 18px;
`;

const Cocktail = styled(CocktailIcon)`
  width: 16px;
  height: 24px;
`;

const Terrace = styled(TerraceIcon)`
  width: 20px;
  height: 20px;
`;

const Globe = styled(GlobeIcon)`
  width: 20px;
  height: 20px;
`;

const Aperol = styled(AperolIcon)`
  width: 16px;
  height: 16px;
`;

const CanceledBall = Star;

const CanceledCart = Star;

const icons = [
  Star,
  Cocktail,
  Shaker,
  CanceledBall,
  CanceledCart,
  Cart,
  Orange,
  Terrace,
  Globe,
];

export const Icon = ({ type, ...props }) => {
  const Icon = icons[type] || Aperol;
  return <Icon {...props} />;
};
