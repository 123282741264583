// import React, { useLayoutEffect } from 'react';
import React from 'react';
import styled from '@emotion/styled/macro';
import Slider from 'react-slick';
// import 'objectFitPolyfill';
import { ReactComponent as Arrow } from './img/arrow.svg';
import './slick.css';

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

const SlickButton = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

const cfg = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  className: 'point-slider',
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <SlickButton>
      <Arrow />
    </SlickButton>
  ),
  nextArrow: (
    <SlickButton>
      <Arrow />
    </SlickButton>
  ),

  responsive: [
    {
      breakpoint: 767,
      settings: {
        // arrows: false,
        // dots: false,
      },
    },
  ],
};

const coverCfg = {
  ...cfg,
  arrows: false,
  dots: false,
};

export const PointSlider = ({ id, slides, cover, ...props }) => {
  // useLayoutEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   objectFitPolyfill();
  // }, []);

  return slides ? (
    <Slider {...cfg} {...props}>
      {slides.map((slide, index) => (
        <Img src={slide.large} alt="" key={id + index} data-object-fit />
      ))}
    </Slider>
  ) : (
    <Slider {...coverCfg}>
      <Img src={cover.large} data-object-fit />
    </Slider>
  );
};
