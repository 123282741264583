export default [
  {
    label: 'Все события',
    value: '',
  },
  {
    label: 'Лето в Globus',
    value: 8,
  },
  {
    options: [
      {
        label: 'Дегустации',
        value: 6,
      },
      {
        label: 'Мероприятия',
        value: 0,
      },
    ],
  },
  {
    options: [
      {
        label: 'Акции баров',
        value: 2,
      },
      {
        label: 'Акции магазинов',
        value: 5,
      },
    ],
  },
  {
    label: 'Флагманские заведения',
    value: 1,
  },
  {
    label: 'Террасы',
    value: 7,
  },
];
