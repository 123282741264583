import React from 'react';
import styled from '@emotion/styled/macro';
import bottle from './img/bottle.svg';

const Img = styled.div`
  opacity: 0.3;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  overflow: hidden;

  img {
    display: block;
    max-height: 100%;
    width: auto;
  }
`;

export const Bottle = props => (
  <Img {...props}>
    <img src={bottle} alt="" />
  </Img>
);
