import React from 'react';
import styled from '@emotion/styled/macro';
import { Btn } from '../btn';
import { Logo } from '../logo';
import { Wrapper, Content, Heading, Answers, StyledBottle } from './components';
import { pushEvent } from '../gtm';

const Answer = styled(Btn)`
  display: block;
  width: 150px;
  max-width: 150px;
  margin: 0 16px 0 0;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 767px) {
    margin: 0 0 8px;
  }
`;

const AnswerNo = styled(Answer)`
  color: #fff;
  background: #fc5001;

  &:hover {
    color: #000;
    background: #fff;
  }
`;

export const Age = ({ answerHandler, ...props }) => (
  <Wrapper {...props}>
    <Content>
      <Logo />

      {!!+process.env.REACT_APP_CORDOVA ? (
        <Heading>
          Доступно только <br />
          для лиц старше 18 лет
        </Heading>
      ) : (
        <Heading>
          Сайт доступен только <br />
          для лиц старше 18 лет
        </Heading>
      )}

      <Answers>
        <Answer
          onClick={() => {
            answerHandler(true);
            pushEvent('Мне есть 18');
          }}
        >
          Да, мне есть 18
        </Answer>

        <AnswerNo
          onClick={() => {
            answerHandler(false);
            pushEvent('Мне нет 18');
          }}
        >
          Нет, мне нет 18
        </AnswerNo>
      </Answers>
    </Content>

    <StyledBottle />
  </Wrapper>
);
