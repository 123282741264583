import React from 'react';
import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 110%;
  color: #ffffff;
  opacity: 0.4;

  span {
    display: block;
  }
`;

export const Company = props => (
  <Wrapper {...props}>
    <span>© Davide Campari-Milano S.p.A. — 20099 Sesto San Giovanni,</span>
    <span>Via Franco Sacchetti 20 — P.Iva: 06672120158</span>
  </Wrapper>
);
