import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { Aperol } from '../context';
import { Heading } from '../typo';
import first from './img/1.png';
import second from './img/2.png';
import third from './img/3.png';
import fourth from './img/4.png';
import fifth from './img/5.png';

const Wrapper = styled.section`
  position: relative;
  z-index: 10;
  padding: 20px 0 40px;
  background: #fff;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
`;

const Content = styled.div`
  padding: 0 20px;

  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

const Steps = styled.section`
  counter-reset: steps;
`;

const Step = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1660px) {
    margin-bottom: 36px;
  }

  @media (max-width: 1430px) {
    margin-bottom: 24px;
  }

  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

const ShowMe = styled.p`
  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Text = styled.div`
  position: relative;
  padding-left: 60px;

  header {
    color: #fc5001;
    font-size: 64px;
    font-weight: 700;
    line-height: 125%;
    text-transform: uppercase;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      counter-increment: steps;
      content: counter(steps);
    }

    span {
      display: block;
    }
  }

  p {
    margin: 0;
    color: #08304b;
    font-size: 26px;
    font-weight: 500;
    line-height: 145%;
    text-transform: uppercase;

    span {
      display: block;
    }
  }

  ${ShowMe} {
    margin-top: 24px;
  }

  @media (max-width: 1660px) {
    padding-left: 48px;

    header {
      font-size: 52px;
    }

    p {
      font-size: 24px;
    }
  }

  @media (max-width: 1430px) {
    padding-left: 36px;

    header {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }

  @media (max-width: 1200px) {
    padding-left: 24px;

    header {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    padding-left: 0;

    header {
      margin-bottom: 10px;
      padding-left: 20px;
      font-size: 22px;

      span {
        display: inline;
      }
    }

    p {
      font-size: 16px;

      span {
        display: inline;
      }
    }
  }
`;

const Figure = styled.img`
  flex-shrink: 0;
  width: 100%;
  max-width: 500px;
  height: 500px;

  @media (max-width: 1660px) {
    max-width: 400px;
    height: 400px;
  }

  @media (max-width: 1340px) {
    max-width: 300px;
    height: 300px;
  }

  @media (max-width: 1200px) {
    max-width: 200px;
    height: 200px;
  }

  @media (max-width: 767px) {
    order: -1;
    min-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
`;

export const HowTo = props => {
  const { methods } = useContext(Aperol);
  const { toggleFrontView, toggleSearch } = methods;

  useEffect(() => {
    toggleFrontView(false);
    toggleSearch(false);

    return () => {
      toggleFrontView(true);
      toggleSearch(true);
    };
  }, []);

  return (
    <Wrapper {...props}>
      <Content>
        <Heading>
          Рецепт идеального коктейля <span>Aperol Spritz</span>
        </Heading>

        <Steps>
          <Step>
            <Text>
              <header>Начните со льда</header>

              <p>
                Аккуратно наполнив кубиками
                <span>винный бокал</span>
              </p>
            </Text>

            <Figure src={first} alt="Первый шаг" />
          </Step>

          <Step>
            <Text>
              <header>
                Теперь одну часть <span>Cinzano Prosecco</span>
              </header>

              <p>
                Сто миллилитров&nbsp;&ndash; <span>идеальная пропорция!</span>
              </p>
            </Text>

            <Figure src={second} alt="Второй шаг" />
          </Step>

          <Step>
            <Text>
              <header>Одну часть Aperol</header>

              <p>
                Не больше, не меньше&nbsp;&ndash; <span>как просекко</span>
              </p>
            </Text>

            <Figure src={third} alt="Третий шаг" />
          </Step>

          <Step>
            <Text>
              <header>
                Ещё немного <span>газированной воды</span>
              </header>

              <p>
                Пузырьки&nbsp;&ndash; важнейшая часть
                <span>совершенного коктейля</span>
              </p>
            </Text>

            <Figure src={fourth} alt="Четвёртый шаг" />
          </Step>

          <Step>
            <Text>
              <header>
                Украсить долькой <span>апельсина</span>
              </header>

              <p>Солнце в бокале — вечный символ лета!</p>

              <ShowMe>
                <a
                  href="https://www.instagram.com/p/BjpRAJcAn6S/?taken-by=aperolspritzrussia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Не хочу читать, хочу смотреть
                </a>
              </ShowMe>
            </Text>

            <Figure src={fifth} alt="Пятый шаг" />
          </Step>
        </Steps>
      </Content>
    </Wrapper>
  );
};
