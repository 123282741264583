import React from 'react';
import styled from '@emotion/styled/macro';
import Media from 'react-media';
import { Logo } from '../logo';
import { Wrapper, Content, Heading, StyledBottle } from './components';
import disclaimer from './img/disclaimer.png';
import disclaimerMobile from './img/disclaimer-m.png';

const Disclaimer = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: block;
  width: calc(100% - 16px);
  max-width: 100%;
`;

export const NextSummer = props => (
  <Wrapper {...props}>
    <Content>
      <Logo />

      <Heading>
        До встречи
        <br />
        следующим летом!
      </Heading>
    </Content>

    <StyledBottle />

    <Media query="(max-width: 767px)">
      {matches => (
        <Disclaimer src={matches ? disclaimerMobile : disclaimer} alt="" />
      )}
    </Media>
  </Wrapper>
);
