import { intervalRAF, cancelIntervalRAF } from './helpers';
import location from './map/img/location.svg';

const APL = window.APL;

export const locator = () => {
  // Prevent marker from flickering if location doesn't change
  let savedPosition = null;

  // Clear any old interval instances
  if (APL.intervalInstance) {
    cancelIntervalRAF(APL.intervalInstance);
  }

  // Run function with interval
  APL.intervalInstance = intervalRAF(() => {
    navigator.geolocation.getCurrentPosition(position => {
      if (
        !savedPosition ||
        (savedPosition &&
          (savedPosition.latitude !== position.coords.latitude ||
            savedPosition.longitude !== position.coords.longitude))
      ) {
        const loc = new APL.NS.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        if (APL.NS.location && APL.NS.location.setPosition) {
          APL.NS.location.setPosition(loc);
        }

        // Create current location marker using coordinates we got from browser Geolocation API
        if (!APL.NS.location) {
          APL.NS.location = new APL.NS.maps.Marker({
            position: loc,
            map: APL.NS.map,
            icon: {
              url: location,
              scaledSize: new APL.NS.maps.Size(24, 24),
              origin: new APL.NS.maps.Point(0, 0),
              anchor: new APL.NS.maps.Point(12, 12),
            },
          });
        }
      }

      savedPosition = position.coords;
    });
  }, 5000);
};
