import smoothscroll from 'smoothscroll-polyfill';
import React, {
  createRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import { Aperol } from '../context';
import styled from '@emotion/styled/macro';
import { getPoint } from '../api';
import { BtnIconCSS } from '../btn';
import { Back } from '../back';
import { About, Heading } from '../typo';
import { MapEmbed } from '../map';
import { PointSlider } from './PointSlider';
import { PointAdrs } from './PointAdrs';
import { ReactComponent as LinkIcon } from './img/link.svg';

const APE = window.APE;

const Wrapper = styled.section`
  position: relative;
  z-index: 10;
  padding: 30px 0 0;
  min-height: 100vh;
  background: #fff;
`;

const Content = styled.section`
  padding: 0 20px;

  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

const Date = styled.span`
  display: block;
  margin-bottom: 8px;
  color: rgba(8, 48, 75, 0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`;

const SubHeading = styled.p`
  margin: 0;
  margin-bottom: 16px;
  color: rgba(252, 80, 1, 0.6);
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 125%;
  }
`;

const Info = styled.div`
  margin: 0 0 30px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const Address = styled.p`
  margin: 0;
  color: #08304b;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
`;

const ExtLink = styled.a`
  line-height: 120%;
  white-space: nowrap;
  font-size: 14px;
  color: #fc5001;
  text-decoration: underline;

  svg {
    display: none;
  }

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    ${BtnIconCSS}
    font-size: 0;

    svg {
      display: block;
    }
  }
`;

const SliderWrapper = styled.div`
  margin-bottom: 44px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const Point = ({ match, ...props }) => {
  const mapEmbedRef = createRef();

  useLayoutEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const { methods } = useContext(Aperol);
  const { toggleFrontView } = methods;

  useEffect(() => {
    toggleFrontView(false);

    return () => {
      toggleFrontView(true);
    };
  }, []);

  const [point, setPoint] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const { id } = match.params;

  useEffect(() => {
    const fetchData = () => {
      getPoint(id, { include: 'city,gallery' }).then(point => {
        setPoint(point);
        setMapCenter(point);
      });
    };

    fetchData();
  }, [id]);

  const showAdrs = center => {
    const { lat, lng } = center;
    APE.map.panTo({ lat, lng });
    window.scrollTo(0, mapEmbedRef.current.offsetTop);
  };

  const showGallery =
    point &&
    point.gallery &&
    point.gallery.data &&
    point.gallery.data.length > 0;

  return (
    <Wrapper {...props}>
      {point && (
        <>
          <Content>
            <Back to="/" />

            <Date>
              {point.from} — {point.to}
              {point.time ? `, ${point.time}` : ''}
            </Date>

            <StyledHeading>{point.place}</StyledHeading>

            <SubHeading>{point.title}</SubHeading>

            <Info>
              <Address>
                {point.city.data.name &&
                  (point.address
                    ? point.city.data.name + ', '
                    : point.city.data.name)}
                {point.address}
                {point.whpd ? `, ${point.whpd}` : ''}
              </Address>

              {point.link && (
                <ExtLink
                  href={point.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {point.link}
                  <LinkIcon />
                </ExtLink>
              )}
            </Info>

            {showGallery ? (
              <SliderWrapper>
                <PointSlider id={point.id} slides={point.gallery.data} />
              </SliderWrapper>
            ) : point.cover ? (
              <SliderWrapper>
                <PointSlider cover={point.cover} />
              </SliderWrapper>
            ) : null}

            <About>
              <article dangerouslySetInnerHTML={{ __html: point.short }} />
            </About>

            <PointAdrs point={point} handleClick={showAdrs} />
          </Content>
          {mapCenter && <MapEmbed ref={mapEmbedRef} point={mapCenter} />}
        </>
      )}
    </Wrapper>
  );
};
