// import React, { useLayoutEffect } from 'react';
import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Route } from '../route';
import { ReactComponent as CloseIcon } from './img/close.svg';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';
import cover from './img/cover.png';
import MOCK from './mock';

const Box = styled.article`
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Futura PT', sans-serif;
  background-color: #fff;
  cursor: default;
`;

const Arrow = styled(ArrowIcon)`
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 20px;
  height: 10px;
  transform: translateX(-50%);
`;

const Header = styled(Link)`
  display: block;
  position: relative;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
  text-decoration: none;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  @media (max-width: 767px) {
    display: ${({ visible }) => (visible === 'true' ? 'block' : 'none')};
  }
`;

const Close = styled.button`
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  margin: 0;
  padding: 0;
  z-index: 1;
  background: transparent;
  border: none;
  outline: none;
  transition: all ease-in-out 0.3s;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;

    svg path {
      stroke: #d8d8d8;
    }
  }

  @media (max-width: 767px) {
    svg path {
      stroke: #fc5001;
    }

    &:hover {
      svg path {
        stroke: #fc5001;
        opacity: 0.6;
      }
    }
  }
`;

const Counter = styled.span`
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  border-radius: 20px;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  color: #08304b;
  font-size: 12px;
  line-height: 15px;
  background-color: #fff;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Body = styled.div`
  padding-top: 14px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  @media (max-width: 767px) {
    padding-top: 20px;
  }
`;

const Date = styled.span`
  display: block;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 120%;
  color: rgba(8, 48, 75, 0.6);
`;

const Heading = styled(Link)`
  display: block;
  margin-bottom: 10px;
  color: #fc5001;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  text-decoration: none;
  word-wrap: break-word;
`;

const SubHeading = styled(Link)`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  color: #fc5001;
  text-decoration: none;
  opacity: 0.6;
  word-wrap: break-word;
`;

const Text = styled.div`
  color: #08304b;
  font-size: 14px;
  font-weight: 500;
`;

const Choose = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;

  header {
    margin: 0 auto 0 0;
    color: rgba(8, 48, 75, 0.4);
    font-size: 14px;
    line-height: 120%;
  }
`;

export const Card = ({ type, routeHandler, closeHandler, ...props }) => {
  const entity = props.entity || MOCK;
  const link = `/${type === 'party' ? 'parties' : 'points'}/${entity.id}`;

  // useLayoutEffect(() => {
  //   // eslint-disable-next-line no-undef
  //   objectFitPolyfill();
  // }, []);

  return (
    entity && (
      <Box>
        <Header to={link} onClick={props.gtm} visible={'' + !!!closeHandler}>
          <Image
            src={entity.cover ? entity.cover.medium : cover}
            data-object-fit
          />

          {entity.photos > 0 && <Counter>{entity.photos} фото</Counter>}
        </Header>

        {closeHandler && (
          <Close
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              closeHandler();
            }}
          >
            <CloseIcon />
          </Close>
        )}

        <Body>
          <Date>
            {type === 'party'
              ? `${entity.from}${entity.time ? `, ${entity.time}` : ''}`
              : `${entity.from} — ${entity.to}`}
          </Date>

          <Heading to={link} onClick={props.gtm}>
            {entity.place}
          </Heading>

          <SubHeading to={link} onClick={props.gtm}>
            {entity.title}
          </SubHeading>

          <Text>
            {entity.city.data && entity.city.data.name}, {entity.address}
          </Text>

          {routeHandler && (
            <Choose>
              <header>Построить маршрут</header>

              <Route
                type="DRIVING"
                onClick={() => {
                  routeHandler('DRIVING');
                }}
              />

              <Route
                type="WALKING"
                onClick={() => {
                  routeHandler('WALKING');
                }}
              />
            </Choose>
          )}
        </Body>

        {props.arrow && <Arrow />}
      </Box>
    )
  );
};
