import React, { useRef } from 'react';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled from '@emotion/styled/macro';
import { Card } from '../card';
import './slick.css';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const Wrapper = styled.section``;

const Top = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const Heading = styled.header`
  margin-right: 10px;
  color: #08304b;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-right: 0;
    font-size: 16px;
  }
`;

const Arrows = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Arrow = styled.button`
  position: relative;
  width: 20px;
  height: 16px;
  border: none;
  background-color: transparent;
  outline: none;
  transition: all ease-in-out 0.15s;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const PrevArrow = styled(Arrow)`
  @media (max-width: 767px) {
    display: none;
  }
`;

const NextArrow = styled(Arrow)`
  svg {
    transform: translate(-50%, -50%) scale(-1, -1);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const cfg = {
  arrows: false,
  dots: false,
  className: 'card-slider',
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,

  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
        variableWidth: false,
      },
    },
  ],
};

export const CardsSlider = ({ cards, heading }) => {
  const sliderRef = useRef(null);

  const handleArrowClick = type => {
    if (type === 'prev') {
      sliderRef.current.slickPrev();

      return;
    }

    sliderRef.current.slickNext();
  };

  return (
    <Wrapper>
      <Top>
        <Heading>{heading}</Heading>

        {cards.length > 2 && (
          <Arrows>
            <PrevArrow onClick={() => handleArrowClick('prev')}>
              <ArrowIcon />
            </PrevArrow>

            <NextArrow onClick={() => handleArrowClick('next')}>
              <ArrowIcon />
            </NextArrow>
          </Arrows>
        )}
      </Top>

      <Slider ref={sliderRef} {...cfg}>
        {cards.map((card, index) => (
          <Card key={card.id + index} entity={card} />
        ))}
      </Slider>
    </Wrapper>
  );
};
