import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { getPoints } from '../api';
import { Btn } from '../btn';
import { Card } from '../card';
import { CardsSlider } from '../cards-slider';
import { Empty } from '../empty';

const filters = [
  'Мероприятия',
  'Флагманские заведения',
  'Акции баров',
  'Спортивные бары (Отменено)',
  'Где купить (Отменено)',
  'Акции магазинов',
  'Дегустации',
  'Все события',
  'Террасы',
];

const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: ${({ view }) => (view === 'list' ? 'block' : 'none')};

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const Content = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 767px) {
    padding-top: 70px;
    padding-right: 10px;
    padding-bottom: 60px;
    padding-left: 10px;
  }
`;

const Heading = styled.header`
  margin-bottom: 40px;
  color: #08304b;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  text-transform: uppercase;

  @media (max-width: 767px) {
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

const Break = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 34px;
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    display: none;
  }
`;

const Cards = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  article {
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
    text-decoration: none;

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledEmpty = styled(Empty)`
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 0;
  }
`;

export const List = ({ view, city, icon, from }) => {
  const [points, setPoints] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);

  const [promo, setPromo] = useState([]);
  const [recommend, setRecommend] = useState([]);

  useEffect(() => {
    if (!city.id) {
      return;
    }

    getPoints(city.id, {
      icon,
      from,
      include: 'city',
    }).then(r => {
      setPoints(r.data);
      setMeta(r.meta);
    });
  }, [city, icon, from]);

  useEffect(() => {
    if (!city.id) {
      return;
    }

    getPoints(city.id, {
      icon,
      from,
      page,
      include: 'city',
    }).then(r => {
      setPoints([...points, ...r.data]);
      setMeta(r.meta);
    });
  }, [page]);

  useEffect(() => {
    if (!city.id) {
      return;
    }

    getPoints(city.id, { promo: 1, from, include: 'city' }).then(promo =>
      setPromo(promo)
    );

    getPoints(city.id, { recommend: 1, from, include: 'city' }).then(
      recommend => setRecommend(recommend)
    );
  }, [city]);

  const showPoints = points && points.length > 0;
  const showMore =
    meta && meta.pagination.current_page < meta.pagination.total_pages;

  const showPromo = promo && promo.data && promo.data.length > 0;
  const showRecommend =
    recommend && recommend.data && recommend.data.length > 0;

  return (
    city.id && (
      <Wrapper view={view}>
        <Content>
          {showPromo && (
            <>
              <CardsSlider cards={promo.data} heading="Промо" />
              <Break />
            </>
          )}

          <Heading>{filters[icon] || 'Все события'}</Heading>

          {showPoints ? (
            <>
              <Cards>
                {points.map(point => (
                  <Card entity={point} key={`list_${point.id}`} />
                ))}
              </Cards>

              {showMore && (
                <Btn
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Показать больше
                </Btn>
              )}
            </>
          ) : (
            <StyledEmpty />
          )}

          {showRecommend && (
            <>
              <Break />
              <CardsSlider cards={recommend.data} heading="Рекомендуем" />
            </>
          )}
        </Content>
      </Wrapper>
    )
  );
};
