import React from 'react';
import { Logo } from '../logo';
import { Btn } from '../btn';
import { Icon } from '../icon';
import { Wrapper, Content, Heading, Answers, StyledBottle } from './components';

export const Welcome = ({ answerHandler, ...props }) => (
  <Wrapper {...props}>
    <Content>
      <Logo />

      <Heading>
        Здесь вы найдете информацию о самых ярких событиях города{' '}
        <span>
          с Aperol Spritz. Выберите, что вас интересует больше&nbsp;всего:
        </span>
      </Heading>

      <Answers>
        <Btn onClick={() => answerHandler(6)}>
          <Icon type={6} />
          <span>Дегустации</span>
        </Btn>

        <Btn onClick={() => answerHandler(0)}>
          <Icon type={0} />
          <span>Мероприятия</span>
        </Btn>

        <Btn onClick={() => answerHandler(2)}>
          <Icon type={2} />
          <span>Акции баров</span>
        </Btn>

        <Btn onClick={() => answerHandler(5)}>
          <Icon type={5} />
          <span>Акции магазинов</span>
        </Btn>

        <Btn onClick={() => answerHandler(1)}>
          <Icon type={1} />
          <span>Флагманские заведения</span>
        </Btn>

        <Btn onClick={() => answerHandler(1)}>
          <Icon type={7} />
          <span>Террасы</span>
        </Btn>
      </Answers>
    </Content>

    <StyledBottle />
  </Wrapper>
);
