import React from 'react';
import styled from '@emotion/styled/macro';
import Select, { components } from 'react-select';
import { getValue } from '../helpers';
import { styles } from './styles';
import { options } from './options';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const Wrapper = styled.div`
  display: block;
  position: fixed;
  top: 20px;
  left: 420px;
  z-index: 80;

  @media (max-width: 1600px) {
    left: 380px;
  }

  @media (max-width: 1024px) {
    left: 80px;

    + div > header {
      padding-left: 0;
    }
  }

  @media (max-width: 767px) {
    display: block;
    width: calc(100% - 20px);
    position: relative;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Container = styled.div`
  display: flex;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .ap-filter {
    width: 100%;
    min-width: 200px;
    min-height: 40px;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  min-width: 200px;
  min-height: 40px;
`;

const Label = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 20px 0px 0px 20px;
  color: #fff;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
  background-color: #fc5001;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowIcon />
  </components.DropdownIndicator>
);

export const PartiesFilter = ({ handleChange, period, ...props }) => {
  const val = getValue(period, options);

  return (
    <Wrapper {...props}>
      <Container>
        <Label>Показать</Label>

        <StyledSelect
          value={val}
          onChange={handleChange}
          options={options}
          isSearchable={false}
          components={{ DropdownIndicator }}
          styles={styles}
        />
      </Container>
    </Wrapper>
  );
};
