import React from 'react';
import styled from '@emotion/styled/macro';
import { isMobile } from 'react-device-detect';
import { ReactComponent as Vk } from './img/vk.svg';
import { ReactComponent as Ok } from './img/ok.svg';
import { ReactComponent as Twitter } from './img/twitter.svg';
import { ReactComponent as Fb } from './img/fb.svg';

const Share = styled.aside`
  display: flex;
  align-items: center;
  margin-left: auto;

  header {
    color: #08304b;
    font-size: 14px;
    line-height: 120%;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const Items = styled.nav`
  margin-left: 20px;
  display: flex;
`;

const Item = styled.button`
  display: block;
  background: none;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  transition: ease-in-out 0.3s;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export default ({ title, ...props }) => {
  const doShare = (provider = 'fb') => {
    if (!window.location.origin) {
      window.location.origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }

    const page = encodeURIComponent(window.location.href);

    let url = '';
    switch (provider) {
      case 'vk':
        url += 'http://vkontakte.ru/share.php';
        url += `?url=${page}&title=${title}`;
        break;
      case 'ok':
        url += 'https://connect.ok.ru/offer';
        url += `?url=${page}&title=${title}`;
        break;
      case 'tw':
        url += 'https://twitter.com/intent/tweet';
        url += `?url=${page}&text=${title}`;
        break;
      case 'fb':
      default:
        url += 'http://www.facebook.com/sharer.php';
        url += `?u=${page}&title=${title}`;
        break;
    }

    if (isMobile) {
      window.location.href = url;
      return;
    }

    const TB = 'width=640,height=480,location=no,toolbar=no,menubar=no';
    const win = window.open('', '', TB);
    win.document.location = url;

    return false;
  };

  return (
    <Share {...props}>
      <header>Поделиться</header>

      <Items>
        <Item onClick={() => doShare('vk')}>
          <Vk />
        </Item>

        <Item onClick={() => doShare('ok')}>
          <Ok />
        </Item>

        <Item onClick={() => doShare('tw')}>
          <Twitter />
        </Item>

        <Item onClick={() => doShare('fb')}>
          <Fb />
        </Item>
      </Items>
    </Share>
  );
};
