const dataLayer = window.dataLayer || [];

export const pushEvent = eventAction => {
  // const [eventAction = ''] = args;

  if (process.env.NODE_ENV === 'development') {
    console.log({
      eventAction,
    });
  }

  dataLayer.push({
    event: 'GA_Event',
    eventCategory: 'commonClick',
    eventAction,
    eventLabel: window.location.href,
  });

  if (process.env.NODE_ENV === 'development') {
    console.log(dataLayer);
  }
};
