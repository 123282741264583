import React from 'react';
import styled from '@emotion/styled/macro';
import { BtnIcon } from '../btn';
import { ReactComponent as DriveIcon } from './img/drive.svg';
import { ReactComponent as WalkIcon } from './img/walk.svg';

const Btn = styled(BtnIcon)`
  svg {
    width: ${({ type }) => (type === 'WALKING' ? '13px' : '22px')};
    height: ${({ type }) => (type === 'WALKING' ? '22px' : '17px')};
  }
`;

export const Route = ({ type, lat, lng, ...props }) => (
  <Btn type={type} lat={lat} lng={lng} {...props}>
    {type === 'DRIVING' ? <DriveIcon /> : <WalkIcon />}
  </Btn>
);
