import React from 'react';
import { Logo } from '../logo';
import { Wrapper, Content, Heading, StyledBottle } from './components';

export const Sorry = props => (
  <Wrapper {...props}>
    <Content>
      <Logo />

      <Heading>
        Мы не эйджисты,
        <br />
        но таков закон.
        <br />
        хорошего лета!
      </Heading>
    </Content>

    <StyledBottle />
  </Wrapper>
);
