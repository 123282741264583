import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as Warn } from './img/alco.svg';

const Text = styled.footer`
  display: flex;
  justify-content: center;
  pointer-events: none;

  svg {
    display: block;
    max-width: calc(100% - 40px);
  }

  @media (max-width: 767px) {
    svg {
      max-width: calc(100% - 20px);
    }
  }
`;

export const Alco = props => (
  // Heading Compressed Pro font is weight more than SVG
  // Also plain text is harder to fit in the layout
  <Text {...props}>
    <Warn />
  </Text>
);
