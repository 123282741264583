import React from 'react';
import Slider from 'react-slick';
import styled from '@emotion/styled/macro';
import { addDays, subDays } from 'date-fns';
// import { pushEvent } from '../gtm';
import './slick.css';

const monthNames = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

const dateSliderConfig = {
  className: 'ap-date-slider',
  centerMode: true,
  arrows: false,
  dots: false,
  infinite: false,
  initialSlide: 7,
  centerPadding: '0px',
  slidesToShow: 15,
  speed: 500,
  swipe: false,

  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 11,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

const Day = styled.span`
  display: block;
  color: #08304b;
  font-size: 24px;
  line-height: 130%;
  user-select: none;
`;

const Month = styled.span`
  display: block;
  color: #08304b;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  user-select: none;
`;

const Date = ({ date, setDate }) => {
  const handleClick = () => {
    // pushEvent('Клик на календарь');
    setDate(date);
  };

  return (
    <div onClick={handleClick}>
      <Day>{date.getDate()}</Day>
      <Month>{monthNames[date.getMonth()]}</Month>
    </div>
  );
};

export default ({ date, setDate }) => {
  const prevWeekStart = subDays(date, 7);

  const renderPrevWeek = () => {
    const dates = [];

    for (let day = 0; day < 7; day++) {
      const modDate = addDays(prevWeekStart, day);

      dates.push(<Date date={modDate} setDate={setDate} key={modDate} />);
    }

    return dates;
  };

  const renderNextWeek = () => {
    const dates = [];

    for (let day = 1; day < 8; day++) {
      const modDate = addDays(date, day);

      dates.push(<Date date={modDate} setDate={setDate} key={modDate} />);
    }

    return dates;
  };

  return (
    <Slider {...dateSliderConfig}>
      {renderPrevWeek()}
      <Date date={date} setDate={setDate} />
      {renderNextWeek()}
    </Slider>
  );
};
