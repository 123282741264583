import React, { useContext } from 'react';
import { Aperol } from '../context';
import styled from '@emotion/styled/macro';
import { ReactComponent as InstagramIcon } from './img/instagram.svg';
import { ReactComponent as FacebookIcon } from './img/facebook.svg';
import { pushEvent } from '../gtm';

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    color: #fff;
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    line-height: 100%;
    text-decoration: none;

    &:first-of-type {
      margin-right: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Social = props => {
  const { state } = useContext(Aperol);
  const { settings } = state;

  const showSocial = state.settings;
  const showFb = settings && settings.fb;
  const showIg = settings && settings.ig;

  return (
    showSocial && (
      <Items {...props}>
        {showFb && (
          <a
            href={settings.fb}
            title=""
            onClick={() => pushEvent('Клик на соцсеть / Facebook')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        )}

        {showIg && (
          <a
            href={settings.ig}
            title=""
            onClick={() => pushEvent('Клик на соцсеть / Instagram')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        )}
      </Items>
    )
  );
};
