import React, { useEffect, useState } from 'react';
import { getPromos } from '../api';
import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import Counter from '../counter';

const Items = styled.nav`
  a {
    display: flex;
    position: relative;
    margin: 20px 0;
    padding-left: 20px;
    color: #fff;
    opacity: 0.6;
    font-size: 14px;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: ${({ type }) => (type === 'dot' ? "''" : null)};
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      transform: translateY(-50%);
    }

    &:hover,
    &.active {
      opacity: 1;
    }

    &.active::before {
      background-color: #fff;
    }

    @media (max-width: 767px) {
      padding-left: 15px;
    }
  }
`;

export const Nav = ({ count, ...props }) => {
  const [promos, setPromos] = useState([]);
  useEffect(() => {
    getPromos({ menu: 1 }).then(r => {
      setPromos(r.data);
    });
  }, []);

  return (
    <Items {...props}>
      <NavLink to="/" exact type="dot">
        <span>События</span>
        <Counter value={count} />
      </NavLink>

      <NavLink to="/how-to">
        <span>Как приготовить Aperol</span>
      </NavLink>

      <NavLink to="/parties">
        <span>Фотоальбомы</span>
      </NavLink>

      <NavLink to="/feedback">
        <span>Связаться с нами</span>
      </NavLink>

      {promos.length > 0 &&
        promos.map(item => (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            title=""
            style={{ color: item.color || '#ffffff' }}
          >
            <span>{item.title}</span>
          </a>
        ))}
    </Items>
  );
};
