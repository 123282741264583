import React, { useContext } from 'react';
import { Aperol } from '../context';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Promo } from '../promo';
import { SwitchMobile } from '../switch';
import { ReactComponent as Logo } from './img/logo.svg';

const Wrapper = styled.header`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    display: block;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
  height: 60px;
  background-color: #fff;
`;

const Menu = styled.button`
  display: block;
  margin: 0;
  border: 0;
  padding: 10px;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  transition: ease 0.2s;
  cursor: pointer;
  overflow: visible;

  .box {
    display: block;
    position: relative;
    width: 20px;
    height: 16px;
  }

  .inner {
    display: block;
    position: absolute;
    top: 0;
    width: 20px;
    height: 2px;
    background-color: #08304b;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    transform: ${props =>
      props.active ? 'translate3d(0, 7px, 0) rotate(45deg)' : null};

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      top: 7px;
      left: 0px;
      background-color: #08304b;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform, opacity;
      transform: ${props =>
        props.active ? 'rotate(-45deg) translate3d(-5.71429px,-6px,0)' : null};
      opacity: ${props => (props.active ? '0' : null)};
    }

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: #08304b;
      top: 14px;
      left: 0px;
      transition-timing-function: ease;
      transition-duration: 0.15s;
      transition-property: transform;
      transform: ${({ active }) =>
        active ? 'translate3d(0,-15px,0) rotate(-90deg)' : null};
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 75px;
    height: 42px;
  }
`;

export const Header = props => {
  const { state, methods } = useContext(Aperol);
  const { view, isSidebarOpen, isFrontView, showPromo } = state;
  const { toggleSidebar, switchView, togglePromo } = methods;

  return (
    <Wrapper {...props}>
      <Promo mobile as="header" visible={showPromo} togglePromo={togglePromo} />

      <Container>
        <Menu active={isSidebarOpen} onClick={toggleSidebar}>
          <span className="box">
            <span className="inner" />
          </span>
        </Menu>

        <StyledLink to="/">
          <Logo />
        </StyledLink>

        {isFrontView && (
          <SwitchMobile view={view} force switchView={switchView} />
        )}
      </Container>
    </Wrapper>
  );
};
