import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { isMobile } from 'react-device-detect';
import { Aperol } from '../context';
import DateSlider from '../date-slider';
import Datepicker from '../datepicker';
import { ReactComponent as ArrowIcon } from './img/arrow.svg';

const Footer = styled.footer`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 80;
  width: calc(100% - 400px);
  height: 80px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.8);

  @media (max-width: 1600px) {
    width: calc(100% - 360px);
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    height: 60px;
    padding-left: 60px;
    padding-right: 60px;
    ${({ showPromo }) =>
      showPromo
        ? `
      pointer-events: none;
      background-color: transparent;
    `
        : ''};
  }
`;

const Arrow = styled(ArrowIcon)`
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 10px;
  height: 8px;
  transform: translateX(-50%);
`;

export const Panel = props => {
  const { state, methods } = useContext(Aperol);
  const { date } = state;
  const { setDate } = methods;
  const { showPromo } = props;

  const showDates = (isMobile && !showPromo) || !isMobile;

  return (
    <Footer {...props}>
      {showDates && (
        <>
          <DateSlider date={date} setDate={setDate} />
          <Arrow />
        </>
      )}
      <Datepicker date={date} setDate={setDate} />
    </Footer>
  );
};
