import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { Aperol } from '../context';
import { pushEvent } from '../gtm';

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  a {
    margin-bottom: 10px;
    color: #fff;
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    line-height: 100%;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 0.6;
    }
  }
`;

export const Legal = props => {
  const { state } = useContext(Aperol);
  const { settings } = state;

  const showLegal = state.settings;
  const showPrivacyPolicy = settings && settings.privacy_policy;
  const showCookiePolicy = settings && settings.cookie_policy;
  const showTermsAndConditions = settings && settings.terms_and_conditions;

  return (
    showLegal && (
      <Items {...props}>
        {showPrivacyPolicy && (
          <a
            href={settings.privacy_policy.path}
            onClick={() =>
              pushEvent('Клик на документы / Политика конфиденциальности')
            }
            target="_blank"
            rel="noopener noreferrer"
            title="PDF"
          >
            Политика конфиденциальности
          </a>
        )}

        {showTermsAndConditions && (
          <a
            href={settings.terms_and_conditions.path}
            onClick={() => pushEvent('Клик на документы / Другие условия')}
            target="_blank"
            rel="noopener noreferrer"
            title="PDF"
          >
            Другие условия
          </a>
        )}

        {showCookiePolicy && (
          <a
            href={settings.cookie_policy.path}
            onClick={() =>
              pushEvent('Клик на документы / Политика обработки данных')
            }
            target="_blank"
            rel="noopener noreferrer"
            title="PDF"
          >
            Политика обработки данных
          </a>
        )}
      </Items>
    )
  );
};
