import React, { Component, createRef } from 'react';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import styled from '@emotion/styled/macro';
// import { intervalRAF, cancelIntervalRAF } from '../helpers';
import { locator } from '../locator';
import { Marker, Cluster } from '../marker';
import { MapControls, ZoomIn, ZoomOut, Locate } from './MapControls';
import { Alco } from './Alco';
import { Empty } from '../empty';
import styles from './styles';
// import location from './img/location.svg';

const AP = window.AP;
const APL = window.APL;
const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;

  @media (max-width: 767px) {
    height: calc(var(--vh, 1vh) * 100 - 100px + 60px);
  }
`;

const StyledControls = styled(MapControls)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);

  @media (max-width: 767px) {
    position: fixed;
    transform: none;
    top: auto;
    bottom: 80px;
    right: 10px;
  }
`;

const StyledZoomOut = styled(ZoomOut)`
  margin: 20px 0;
`;

const StyledAlco = styled(Alco)`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;

  @media (max-width: 767px) {
    position: fixed;
    bottom: ${({ showPromo }) => (showPromo ? '0' : '50px')};
  }
`;

export class Map extends Component {
  constructor(props) {
    super(props);

    this.intervalInstance = null;
    this.zoomInRef = createRef();
    this.zoomOutRef = createRef();
    this.locateRef = createRef();

    const { city } = props;

    this.state = {
      points: [],
      prevPoints: [],
      clusters: [],
      options: {
        // center: { lat: 55.95218717795308, lng: 37.66774151914001 },
        center: city,
        zoom: 11,
        bounds: null,
      },
    };

    this.handleMapChange = this.handleMapChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.points !== prevProps.points) {
      const { points } = this.props;
      this.setState({ points }, () => {
        this.createClusters();
      });
    }
  }

  handleRoute(point) {
    const { points } = this.state;
    const prevPoints = points;

    this.setState({ points: [point], prevPoints }, () => {});
  }

  handleRouteCancel() {
    const { prevPoints } = this.state;

    this.setState({ points: prevPoints, prevPoints: [] }, () => {
      this.createClusters();
    });
  }

  getClusters = () => {
    const { points } = this.state;
    const { options } = this.state;

    const clusters = supercluster(points);

    return clusters(options);
  };

  createClusters = () => {
    const { options } = this.state;

    this.setState({
      clusters: options.bounds
        ? this.getClusters().map(({ wx, wy, numPoints, points }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: `${numPoints}_${points[0].id}`,
            points,
          }))
        : [],
    });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    const { state } = this;
    const { points } = state;

    this.setState(
      {
        ...state,
        options: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        if (points.length > 1 || points.length === 0) {
          this.createClusters();
        }
      }
    );
  };

  handleApiLoaded = (map, maps) => {
    AP.map = map;
    AP.maps = maps;

    AP.directionsDisplay = new maps.DirectionsRenderer();
    AP.directionsDisplay.setOptions({
      preserveViewport: true,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: '#fc5001',
        strokeWeight: 3,
      },
    });

    maps.Map.prototype.panToWithOffset = function(latlng, offsetX, offsetY) {
      const overlay = new maps.OverlayView();
      const map = this;

      overlay.onAdd = function() {
        const proj = this.getProjection();
        const aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x = aPoint.x + offsetX;
        aPoint.y = aPoint.y + offsetY;
        map.panTo(proj.fromContainerPixelToLatLng(aPoint));
      };

      overlay.draw = function() {};
      overlay.setMap(map);
    };

    map.setOptions({
      styles,
      disableDefaultUI: true,
      clickableIcons: false,
      gestureHandling: 'greedy',
    });

    maps.event.addDomListener(this.zoomInRef.current, 'click', () => {
      map.setZoom(map.getZoom() + 1);
    });

    maps.event.addDomListener(this.zoomOutRef.current, 'click', () => {
      map.setZoom(map.getZoom() - 1);
    });

    maps.event.addDomListener(this.locateRef.current, 'click', () => {
      APL.NS = AP;
      locator();

      navigator.geolocation.getCurrentPosition(position => {
        const loc = new AP.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        AP.map.panTo(loc);
      });
    });
  };

  render() {
    const { points, clusters, options } = this.state;
    const { city, showPromo } = this.props;

    return (
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key }}
          center={city}
          zoom={options.zoom}
          onChange={this.handleMapChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
          {points.length > 1 || points.length === 0 ? (
            clusters.map(item => {
              if (item.numPoints === 1) {
                return (
                  <Marker
                    key={item.id}
                    id={item.points[0].id}
                    lat={item.points[0].lat}
                    lng={item.points[0].lng}
                    thisPoint={item.points[0]}
                    showPromo={showPromo}
                    handleRoute={point => this.handleRoute(point)}
                    handleRouteCancel={() => this.handleRouteCancel()}
                  />
                );
              }

              return (
                <Cluster
                  key={item.id}
                  lat={item.lat}
                  lng={item.lng}
                  points={item.points}
                />
              );
            })
          ) : (
            <Marker
              key={points[0].id}
              id={points[0].id}
              lat={points[0].lat}
              lng={points[0].lng}
              thisPoint={points[0]}
              isInRoute={points[0].travelMode}
              showPromo={showPromo}
              handleRoute={point => this.handleRoute(point)}
              handleRouteCancel={() => this.handleRouteCancel()}
            />
          )}
        </GoogleMapReact>

        <StyledControls>
          <ZoomIn ref={this.zoomInRef} />
          <StyledZoomOut ref={this.zoomOutRef} />
          <Locate ref={this.locateRef} />
        </StyledControls>

        <StyledAlco showPromo={showPromo} />

        {points.length === 0 && <Empty />}
      </Wrapper>
    );
  }
}
